<template>
  <div>
    <ListAndAddCard
      v-if="booking.id"
      :show-add="$auth.userCan('CREATE_PAYMENTS')"
      :path="`bookings/${booking.id}/payments`"
      :request-params="{ with: ['bike', 'createdBy'] }"
      :cols="[
        { text: 'Method', value: 'card_brand' },
        { text: 'Amount', value: 'amount' },
        { text: 'Actions', value: 'actions' },
        { text: 'Created', value: 'created_by' }
      ]"
      :ws-channel="`${booking.id}.payments`"
      ws-event="Payment"
      title="Payments"
      icon="mdi-credit-card-outline"
    >
      <template v-slot:item.created_by="{ item: sundry }">
        <v-tooltip top v-if="sundry.created_by">
          <template v-slot:activator="{ on }">
            <v-avatar size="24" color="primary" v-on="on">
              <img
                v-if="sundry.created_by.profile_img_url"
                :src="sundry.created_by.profile_img_url"
                :alt="sundry.created_by.name">
              <strong v-else class="white--text" style="font-size: 10px;">{{ sundry.created_by.initials }}</strong>
            </v-avatar>
          </template>
          <span>
            Created At: {{ $momentDateTimeHuman(sundry.created_at) }} | Created By: {{ sundry.created_by.name }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.card_brand="{ item }">
        {{ item.card_brand }} <small>{{ item.card_exp_last4 }}</small>
      </template>
      <template v-slot:item.amount="{ item: payment }">
        <strong v-if="payment.refund">
          {{ $currency(payment.amount) }}
        </strong>
        <span v-else>
          {{ $currency(payment.amount) }}          
        </span>
        <v-tooltip v-if="payment.description" top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="grey"
              small
              dense
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ payment.description }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item: payment }">
        <v-btn
          v-if="!payment.refund"
          v-user-can:REFUND_PAYMENTS
          text
          color="error"
          x-small
          @click="openRefundPaymentDialog(payment)"
        >
          Refund
        </v-btn>
      </template>
      <template v-slot:addNew="{ dialog, close }">
        <AddPaymentChooseTypeDialog
          :dialog="dialog"
          :booking="booking"
          @update:dialog="close"
        />
      </template>
    </ListAndAddCard>

    <RefundPaymentDialog
      :dialog.sync="refundDialog"
      :payment="refundPayment"
    />
  </div>
</template>
<script type="text/javascript">
import ListAndAddCard from '@/components/ListAndAddCard'
import HasBooking from '@/mixins/HasBooking'
import AddPaymentChooseTypeDialog from '@/components/payments/AddPaymentChooseTypeDialog'
import RefundPaymentDialog from '@/components/payments/RefundPaymentDialog'

export default {
  name: 'PaymentsLists',

  mixins: [ HasBooking ],

  components: {
    ListAndAddCard,
    AddPaymentChooseTypeDialog,
    RefundPaymentDialog
  },

  data () {
    return {
      refundDialog: false,
      refundPayment: {}
    }
  },

  methods: {
    openRefundPaymentDialog (payment) {
      this.refundDialog = true
      this.refundPayment = payment
    }
  }
}
</script>
