<template>
  <tr
    :class="{'blue lighten-4': sundry.is_labour}"
  >
    <td>
      <v-btn x-small depressed @click.stop="editDialog = true">Edit</v-btn>
      {{ sundry.trimmed_name }} <small class="red--text font-weight-bold">{{ sundry.qty > 1 ? `(x ${sundry.qty})` : null }}</small>
    </td>
    <td :class="{'text-left': true, 'green--text darken-2': !sundry.estimate}" style="width: 90px;">{{ $currency(sundry.price) }}</td>

    <EditSundryDialog
      :dialog.sync="editDialog"
      :sundry="sundry"
      :booking="booking"
    />
  </tr>
</template>
<script>
import HasSundry from '@/mixins/HasSundry'
import HasBooking from '@/mixins/HasBooking'
import HasSundryCalculations from '@/mixins/HasSundryCalculations'
import EditSundryDialog from '@/components/sundries/EditSundryDialog'

export default {
  name: 'AssociatedSundryReviewRow',

  mixins: [HasSundry, HasBooking, HasSundryCalculations],

  components: {
    EditSundryDialog
  },

  data () {
    return  {
      editDialog: false
    }
  }
}
</script>
