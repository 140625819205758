<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="1200px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Approve Extra Sundries</span>
        </v-card-title>
        <v-card-text class="pt-8">

          <v-alert
            v-if="sundries.length === 0 && openedPaymentDialog"
            class="mt-2"
            dense
            text
            outlined
            type="success"
          >
            Payment has been taken successfully, now please confirm the triage!
          </v-alert>

          <v-simple-table
            v-if="sundries.length > 0"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Part / Labour</th>
                  <th class="text-left">Description</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Safety</th>
                  <th class="text-left">Stock</th>
                  <th class="text-left">Expected</th>
                  <th class="text-left">Approved</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="sundry in orderedSundries"
                  :key="sundry.id"
                  :class="{'blue lighten-4': sundry.is_labour}"
                >
                  <td class="py-1">
                    <PartsToOrderPartColumn :sundry="sundry" approve-screen />
                  </td>
                  <td>
                    {{ sundry.description }}
                  </td>
                  <td>{{ $currency(sundry.price) }}</td>
                  <td>
                    <v-icon
                      v-if="sundry.safety_critical"
                      small
                      dense
                      color="green"
                    >
                      mdi-check-circle-outline
                    </v-icon>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <v-icon
                      v-if="sundry.in_stock"
                      small
                      dense
                      color="green"
                    >
                      mdi-check-circle-outline
                    </v-icon>
                    <v-icon
                      v-else
                      small
                      dense
                      color="red"
                    >
                      mdi-close-circle-outline
                    </v-icon>
                  </td>
                  <td>
                    <small v-if="sundry.estimated_delivery_date"><strong>{{ $momentDate(sundry.estimated_delivery_date) }}</strong></small>
                    <span v-else>-</span>
                  </td> 
                  <td>
                    <v-btn
                      :color="sundry.estimate ? 'primary' : 'error'"
                      dense
                      x-small
                      depressed
                      @click="sundry.estimate = !sundry.estimate"
                    >
                      {{ sundry.estimate ? 'Approve' : 'Remove' }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-divider class="mb-6" />

          <v-simple-table
            v-if="sundries.length > 0"
            class="mb-4"
          >
            <template v-slot:default>
              <tbody>
                <tr>
                  <td><strong>Estimated Total</strong></td>
                  <td style="text-align: right;">{{ $currency(estimateTotal) }}</td>
                </tr>
                <tr>
                  <td><strong>New Total</strong></td>
                  <td style="text-align: right;"><strong>{{ $currency(newTotal) }}</strong></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row class="mt-4">
            <v-col v-if="sundries.length > 0">
              <v-btn
                :loading="loading"
                color="red white--text"
                block
                depressed
                @click="declineSundries"
              >
                Decline All Sundries
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                :loading="loading"
                color="green white--text"
                block
                depressed
                @click="confirmSundries"
              >
                Confirm Sundries
              </v-btn>
            </v-col>
          </v-row>

          <FormErrors ref="formErrors" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
import map from 'lodash.map'
import HasDialog from '@/mixins/HasDialog'
import HasBooking from '@/mixins/HasBooking'
import HasBike from '@/mixins/HasBike'
import FormErrors from '@/components/FormErrors'
import PartsToOrderPartColumn from '@/components/dashboard/PartsToOrderPartColumn'

export default {
  name: 'ApproveSundriesDialog',

  mixins: [ HasDialog, HasBooking, HasBike ],

  components: {
    FormErrors,
    PartsToOrderPartColumn
  },

  data () {
    return {
      error: {},
      sundries: [],
      loading: false,
      openedPaymentDialog: false,
      paymentDialog: false
    }
  },

  watch: {
    dialog: {
      immediate: true,
      handler () {
        this.openedPaymentDialog = false
        this.getSundries()
      }
    },
    paymentDialog: {
      handler () {
        this.getSundries()
      }
    }
  },

  computed: {
    estimateTotal () {
      var total = 0
      this.sundries.forEach(sundry => {
        total = total + sundry.price
      })
      return total
    },

    newTotal () {
      var total = 0
      this.sundries.forEach(sundry => {
        if (!sundry.estimate) {
          total = total + sundry.price          
        }
      })
      return total
    },

    orderedSundries () {
      var sundries = []

      this.sundries.forEach(sundry => {
        sundries.push(sundry)

        if (sundry.associated_sundries) {
          sundry.associated_sundries.forEach(associatedSundry => {
            sundries.push(associatedSundry)
          })
        }
      })

      return sundries
    },
  },

  methods: {
    getSundries () {
      this.loading = true
      this.$api.get({
        path: `bookings/${this.booking.id}/sundries`,
        params: {
          with: ['product', 'supplier', 'bookingBike', 'associatedSundries'],
          onlyEstimates: true,
          noAssociated: true,
          noDeclined: true,
          bikeId: this.bike.id,
          orderBy: 'created_at',
          ascending: false,
          limit: 10 * 10 * 10
        }
      })
      .then(({ data: sundries }) => {
        this.sundries = sundries
      })
      .finally(() => this.loading = false)
    },

    confirmSundries () {
      if (this.sundries.length > 0) {
        this.$refs.formErrors.clear()
        this.error = null
        this.loading = true
        var requests = []
        this.sundries.forEach(sundry => {
          requests.push({
            path: `bookings/${this.booking.id}/sundries/${sundry.id}`,
            object: {
              ...sundry,
              ...{
                safety_critical: sundry.safety_critical ? true : false,
                estimate: sundry.estimate ? true : false,
                declined: sundry.estimate ? true : false,
                in_stock: sundry.in_stock ? true : false,
                is_labour: sundry.is_labour ? true : false,
                estimated_delivery_date: sundry.estimated_delivery_date ? this.$moment(sundry.estimated_delivery_date).format('YYYY-MM-DD') : null
              }
            }
          })
        })
        this.$api.persistMultiple('put', requests)
          .then(() => {
            // this.$emit('update:bike', { ...this.bike, ...{ workshop_status: 'STATUS_TRIAGE_CONFIRMED' }})
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
          })
          .finally(() => this.loading = false)
      } else {
        // this.$emit('update:bike', { ...this.bike, ...{ workshop_status: 'STATUS_TRIAGE_CONFIRMED' }})
        this.closeDialog()
      }
    },

    declineSundries () {
      this.sundries = map(this.sundries, (sundry) => {
        return {
          ...sundry,
          declined: true
        }
      })
      this.confirmSundries()
    }
  }
}
</script>
