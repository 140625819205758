<template>
  <div>
    <ListAndAddCard
      v-if="booking.id"
      :show-add="$auth.userCan('CREATE_PAYMENT_LINKS')"
      :path="`bookings/${booking.id}/payment-links`"
      :cols="[
        { text: 'Amount', value: 'amount' },
        { text: 'Date', value: 'created_at' },
        { text: 'Reminder', value: 'reminder_sent_at' },
        { text: 'Paid', value: 'paid_at' }
      ]"
      :ws-channel="`${booking.id}.payment-links`"
      ws-event="PaymentLink"
      title="Payment Links"
      icon="mdi-link-lock"
    >
      <template v-slot:item.amount="{ item }">
        {{ $currency(item.amount) }}
      </template>
      <template v-slot:item.created_at="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            {{ $momentDate(item.created_at) }}
            <v-icon
              v-on="on"
              color="grey"
              small
              dense
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ $momentDateTimeHuman(item.created_at) }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.reminder_sent_at="{ item }">
        {{ item.reminder_sent_at ? $momentDate(item.reminder_sent_at) : '-' }}
        <v-tooltip v-if="item.reminder_sent_at" top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="grey"
              small
              dense
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ $momentDateTimeHuman(item.reminder_sent_at) }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.paid_at="{ item }">
        <div class="d-flex">
          <PaymentLinkNudgeBtn
            v-if="!item.payment"
            v-user-can:UPDATE_PAYMENT_LINKS
            :payment-link="item"
          />
          <PaymentLinkCancelBtn
            v-if="!item.payment"
            v-user-can:DELETE_PAYMENT_LINKS
            :payment-link="item"
          />

          <template v-if="item.payment">{{ $momentDateTimeHuman(item.payment.created_at) }}</template>
        </div>
      </template>
      <template v-slot:addNew="{ dialog, close }">
        <AddPaymentLinkDialog
          :dialog="dialog"
          :booking="booking"
          @update:dialog="close"
        />
      </template>
    </ListAndAddCard>
  </div>
</template>
<script type="text/javascript">
import ListAndAddCard from '@/components/ListAndAddCard'
import HasBooking from '@/mixins/HasBooking'
import AddPaymentLinkDialog from '@/components/payments/AddPaymentLinkDialog'
import PaymentLinkNudgeBtn from '@/components/payments/PaymentLinkNudgeBtn'
import PaymentLinkCancelBtn from '@/components/payments/PaymentLinkCancelBtn'

export default {
  name: 'PaymentLinksList',

  components: {
    ListAndAddCard,
    AddPaymentLinkDialog,
    PaymentLinkNudgeBtn,
    PaymentLinkCancelBtn
  },

  mixins: [ HasBooking ]
}
</script>
