<template>
  <tr>
    <td>
      {{ bike.ref }}
      <strong v-if="bike.asset">
        / {{ bike.asset.number }}        
      </strong>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="grey"
            small
            dense
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ bike.name }}</span>
      </v-tooltip>
    </td>
    <td>
      {{ collection ? bike.collection_status_label : bike.return_status_label }}
    </td>
    <td>
        <router-link
          v-if="manifest"
          :to="{ name: 'manifests.show', params: { manifestId: manifest.id } }"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-avatar color="primary" size="25" class="mt-1" v-on="on" v-if="manifest">
                <img
                  v-if="manifest.driver.profile_img_url"
                  :src="manifest.driver.profile_img_url"
                  :alt="manifest.driver.name">
                <strong v-else class="white--text" style="font-size: 10px;">{{ manifest.driver.initials }}</strong>
              </v-avatar>
            </template>
            <span v-if="manifestPivot">Driver: {{ manifest.driver.name }} | Run Order: {{ manifestPivot.order }}</span>
          </v-tooltip>
        </router-link>
        <v-dialog width="500" v-model="removeManifestDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="manifest"
              v-user-can:DELETE_BOOKING_BIKE_MANIFESTS
              text
              color="error"
              x-small
              v-bind="attrs"
              v-on="on"
            >
              Remove
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline grey lighten-2">
              Remove From Manifest
            </v-card-title>

            <v-card-text class="pt-2">
              Are you sure you want to remove {{ bike.ref }} from this manifest?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="removeManifestDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                :loading="manifestDeleteLoading"
                @click="removeJobFromManifest"
              >
                Remove From Manifest
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </td>
    <td>
      <strong v-if="revisedDate">
        {{ $momentDate(revisedDate) }}
      </strong>
      <span>
        <v-btn
          color="primary"
          class="ml-2"
          outlined
          x-small
          @click="rearrangeDatesDialog = true"
        >
          Re-Schedule
        </v-btn>
      </span>
    </td>
    <td>
      <v-tooltip v-if="manifestPivot.cr_at" top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="success"
            small
            dense
          >
            mdi-check-circle-outline
          </v-icon>
        </template>
        <span>{{ $momentDateTimeHuman(manifestPivot.cr_at) }} by {{ manifestPivot.cr_by ? manifestPivot.cr_by.name : ' - ' }}</span>
      </v-tooltip>
      <span v-else>
        -
      </span>
    </td>
    <td v-if="collection">
      <v-btn
        v-if="showWorkshopBtn"
        :loading="loading"
        color="success"
        outlined
        x-small
        @click="markAs"
      >
        Mark Arrived
      </v-btn>
      <v-tooltip v-else-if="manifestPivot.workshop_at" top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="success"
            small
            dense
          >
            mdi-check-circle-outline
          </v-icon>
        </template>
        <span>{{ $momentDateTimeHuman(manifestPivot.workshop_at) }}</span>
      </v-tooltip>
      <template v-else>-</template>
    </td>

    <BikeReArrangeDatesDialog
      :dialog.sync="rearrangeDatesDialog"
      v-bind="{ bike, booking }"
    />
  </tr>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import HasCollectionOrReturn from '@/mixins/HasCollectionOrReturn'
import BikeReArrangeDatesDialog from '@/components/bikes/BikeReArrangeDatesDialog'

export default {
  name: 'BookingCRBikeRow',

  mixins: [ HasBooking, HasCollectionOrReturn ],

  components: {
    BikeReArrangeDatesDialog
  },

  props: {
    bike: {
      type: Object,
      required: true
    },

    error: {
      type: Object,
      required: false,
      default: () => { return {} }
    }
  },

  data () {
    return {
      loading: false,
      manifestDeleteLoading: false,
      removeManifestDialog: false,
      rearrangeDatesDialog: false
    }
  },

  computed: {
    revisedDate () {
      return this.collection ? this.bike.rearranged_collection_date : this.bike.rearranged_return_date
    },

    manifest () {
      if (this.collection) {
        return this.bike.collection_manifest
      }
      return this.bike.return_manifest
    },

    manifestPivot () {
      if (this.manifest) {
        return this.manifest.booking_bike_manifest
      }
      return {}
    },

    updateStatusObject () {
      if (this.collection) {
        return { workshop_status: 'STATUS_AWAITING_TRIAGE' }
      }
      return { workshop_status: 'STATUS_DISPATCHED' }
    },

    showWorkshopBtn () {
      if (this.collection) {
        return this.bike.available_workshop_statuses.includes('STATUS_AWAITING_TRIAGE')
      }
      return this.bike.available_workshop_statuses.includes('STATUS_DISPATCHED')
    },

    localError: {
      get () {
        return this.error
      },
      set (localValue) {
        this.$emit('update:error', localValue)
      }
    }
  },

  methods: {
    removeJobFromManifest () {
      this.manifestDeleteLoading = true
      this.localError = {}
      this.$api.delete({
        path: `bike-manifests/${this.manifestPivot.id}`
      })
        .then(() => {
          this.removeManifestDialog = false
          this.$emit('update:booking', this.booking)
        })
        .catch(error => {
          this.localError = error
        })
        .finally(() => this.manifestDeleteLoading = false)
    },

    markAs () {
      this.loading = true
      this.$api.persist('put', {
        path: `bookings/${this.booking.id}/bikes/${this.bike.id}`,
        object: {
          ...this.bike,
          ...this.updateStatusObject
        }
      })
        .then(({ data: booking }) => {
          this.localError = {}
          this.$emit('update:booking', booking)
        })
        .catch(error => {
          this.localError = error
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
