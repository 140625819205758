<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="bg-primary text-white">
          <span class="headline">Add Payment Method</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  color="primary"
                >
                  Before taking the customers card detailed you <strong>must</strong> pause the call recording within the <a href="circleloop:">Circleloop Phone App</a>.
                  <v-checkbox
                    v-model="form.paused_phone_recording"
                    label="Phone Recording Paused?"
                  />
                </v-alert>
              </v-col>
            </v-row>

            <template v-if="form.paused_phone_recording">
              <v-row class="mt-5">
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="form.booking_bike_id"
                    :rules="[v => !!v || 'Bike is required']"
                    :items="booking.bikes"
                    :item-text="bike => { return `${bike.manafacturer} ${bike.model} (${bike.ref})` }"
                    :disabled="booking.bikes.length === 1"
                    item-value="id"
                    label="Bike"
                    hint="The bike that this payment link is related to."
                    outlined
                    required
                    autofocus
                    dense
                  />
                </v-col>
              </v-row>
              <v-divider class="mb-5" />
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="form.amount"
                    :rules="[v => !!v || 'Amount is required']"
                    autofocus
                    type="number"
                    step="0.1"
                    min="0"
                    prefix="£"
                    label="Amount"
                    outlined
                    required
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <v-textarea
                    v-model="form.description"
                    rows="2"
                    label="Description"
                    outlined
                    required
                    dense
                  />
                </v-col>
              </v-row>
              <v-divider class="mb-5" />
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="form.cardholder_name"
                    :rules="[v => !!v || 'Cardholder name is required']"
                    label="Cardholder Name"
                    outlined
                    required
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="form.billing_postcode"
                    :rules="[v => !!v || 'Billing postcode is required']"
                    label="Billing Postcode (Please Check)"
                    outlined
                    required
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <div
                    class="card_input"
                    id="card-element"
                  />
                  <div
                    class="v-text-field__details"
                    style="padding-left: 10px; margin-bottom: 8px"
                  >
                    <div
                      class="v-messages theme--light error--text"
                      role="alert"
                    >
                      <div
                        class="v-messages__wrapper"
                      >
                        <div
                          v-if="stripeErrorMessage"
                          class="v-messages__message"
                        >
                          {{ stripeErrorMessage }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </template>

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider class="mt-1" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" text @click="addPaymentMethod" :disabled="!form.paused_phone_recording" :loading="loading">
            Charge Payment Method <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Payment taken successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ error.message }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'NewPaymentMethodDialog',

  mixins: [ HasBooking, HasDialog, HasForm ],

  components: {
    SnackBar,
    FormErrors
  },

  data () {
    return {
      error: {},
      stripeErrorMessage: null,
      loading: false,
      paymentIntent: {}
    }
  },

  computed: {
    oneBike () {
      return this.booking.bikes.length === 1
    },

    bike () {
      var selected = {
        balance: 0
      }
      if (this.form.booking_bike_id) {
        this.booking.bikes.forEach(bike => {
          if (bike.id === this.form.booking_bike_id) {
            selected = bike
          }
        })
      }
      return selected
    }
  },

  watch: {
    dialog: {
      handler: function (dialog) {
        this.paymentIntent = {}
        this.stripeErrorMessage = null

        this.$set(this.form, 'cardholder_name', this.booking.customer_name)
        this.$set(this.form, 'billing_postcode', this.booking.collection_postcode)
        this.$set(this.form, 'description', 'Payment for additional sundries')

        if (this.oneBike && dialog) {
          this.$set(this.form, 'booking_bike_id', this.booking.bikes[0].id)
          this.$set(this.form, 'amount', Math.abs(this.bike.balance || this.booking.balance).toFixed(2))
        }
        if (dialog) {
          let stripeScript = document.createElement('script')
          stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
          document.head.appendChild(stripeScript)
        }
      },
      immediate: true
    },

    'form.paused_phone_recording': function () {
      if (this.form.paused_phone_recording) {
        this.$nextTick(() => {
          // eslint-disable-next-line
          this.stripeInstance = Stripe(process.env.VUE_APP_STRIPE_KEY)

          var style = {
            base: {
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '16px',
              fontFamily: '"Roboto"',
              fontSmoothing: 'antialiased',
              '::placeholder': {
                color: '#646464'
              }
            }
          }

          this.elements = this.stripeInstance.elements({ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }] })
          this.cardElement = this.elements.create('card', { style: style, hidePostalCode: true })
          this.cardElement.mount('#card-element')

          this.cardElement.on('change', ($e) => {
            if ($e.error) {
              this.stripeErrorMessage = $e.error.message
            } else {
              this.stripeErrorMessage = null
            }
          })
        })
      }
    }
  },

  methods: {
    addPaymentMethod () {
      this.$refs.formErrors.clear()
      if (this.$refs.form.validate()) {
        this.loading = true
        
        // eslint-disable-next-line
        this.stripeInstance.createSource(this.cardElement, {
          type: 'card',
          owner: {
            name: this.form.cardholder_name,
            email: this.booking.customer.email,
            address: {
              postal_code: this.form.billing_postcode
            }
          }
        })
          .then(result => {
            if (result.source) {
              this.confirmPaymentIntent(result.source)
            } else {
              this.$refs.formErrors.setErrors(result.error)
              this.loading = false
            }
          })
      }
    },

    confirmPaymentIntent (source) {
      this.$api.persist('post', {
        path: 'payments/confirm',
        object: {
          current_intent: this.paymentIntent.id,
          booking_bike_id: this.form.booking_bike_id,
          amount: this.form.amount,
          source_id: source.id,
          card_last4: source.card.last4,
          card_exp_month: source.card.exp_month,
          card_exp_year: source.card.exp_year,
          card_brand: source.card.brand
        }
      })
        .then(data => {
          this.paymentIntent = data

          if (data.status === 'stripe_error') {
            this.$refs.formErrors.setErrors({
              message: data.message,
              errors: [
                { card: 'Please try again or ask the customer for another card.' }
              ]
            })
          } else {
            this.$refs.successBar.open()
            this.closeDialog()
          }
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
<style type="text/css">
  .card_input {
    margin-bottom: 5px;
    padding: 10px 10px;
    border-radius: 5px;
    border-width: thin;
    border-style: solid;
    border-color: #bebebe;
  }
  .card_input:hover {
    border-color: black;
  }
  .StripeElement--focus {
    border-color: #00a09b;
    border-width: 2px;
    margin-bottom: 3px;
  }
  .StripeElement--invalid {
    border-color: #ff0000!important;
    border-width: 2px!important;
  }
</style>
