var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.booking.id)?_c('ListAndAddCard',{attrs:{"show-add":_vm.$auth.userCan('CREATE_PAYMENT_LINKS'),"path":("bookings/" + (_vm.booking.id) + "/payment-links"),"cols":[
      { text: 'Amount', value: 'amount' },
      { text: 'Date', value: 'created_at' },
      { text: 'Reminder', value: 'reminder_sent_at' },
      { text: 'Paid', value: 'paid_at' }
    ],"ws-channel":((_vm.booking.id) + ".payment-links"),"ws-event":"PaymentLink","title":"Payment Links","icon":"mdi-link-lock"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$currency(item.amount))+" ")]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_vm._v(" "+_vm._s(_vm.$momentDate(item.created_at))+" "),_c('v-icon',_vm._g({attrs:{"color":"grey","small":"","dense":""}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$momentDateTimeHuman(item.created_at)))])])]}},{key:"item.reminder_sent_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.reminder_sent_at ? _vm.$momentDate(item.reminder_sent_at) : '-')+" "),(item.reminder_sent_at)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","small":"","dense":""}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$momentDateTimeHuman(item.reminder_sent_at)))])]):_vm._e()]}},{key:"item.paid_at",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(!item.payment)?_c('PaymentLinkNudgeBtn',{directives:[{name:"user-can",rawName:"v-user-can:UPDATE_PAYMENT_LINKS",arg:"UPDATE_PAYMENT_LINKS"}],attrs:{"payment-link":item}}):_vm._e(),(!item.payment)?_c('PaymentLinkCancelBtn',{directives:[{name:"user-can",rawName:"v-user-can:DELETE_PAYMENT_LINKS",arg:"DELETE_PAYMENT_LINKS"}],attrs:{"payment-link":item}}):_vm._e(),(item.payment)?[_vm._v(_vm._s(_vm.$momentDateTimeHuman(item.payment.created_at)))]:_vm._e()],2)]}},{key:"addNew",fn:function(ref){
    var dialog = ref.dialog;
    var close = ref.close;
return [_c('AddPaymentLinkDialog',{attrs:{"dialog":dialog,"booking":_vm.booking},on:{"update:dialog":close}})]}}],null,false,76811544)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }