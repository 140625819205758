<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Choose Bikes For {{ forType }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="bike in booking.bikes"
              :key="bike.id"
            >
              <v-btn
                :color="isSelected(bike) ? 'primary' : ''"
                :disabled="isDisabled(bike)"
                block
                depressed
                @click="handleBikeClick(bike)"
              >
                {{ bike.name }} <small>({{ bike.service.name }})</small>
                <v-icon
                  v-if="isSelected(bike)"
                  right
                  dense
                >
                  mdi-check-circle-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <FormErrors ref="formErrors" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="markCollected"
          >
            Mark {{ markType }} <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar error ref="errorBar">
      {{ error }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasBooking from '@/mixins/HasBooking'
import HasCollectionOrReturn from '@/mixins/HasCollectionOrReturn'
import find from 'lodash.find'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'BookingCRDialog',

  mixins: [ HasDialog, HasBooking, HasCollectionOrReturn ],

  components: {
    FormErrors,
    SnackBar
  },

  data () {
    return {
      loading: false,
      bikes: [],
      error: null
    }
  },

  computed: {
    updateStatusObject () {
      if (this.collection) {
        return { collection_status: 'STATUS_COLLECTED' }
      }
      return { return_status: 'STATUS_RETURNED' }
    }
  },

  methods: {
    handleBikeClick (bike) {
      if (this.isSelected(bike)) {
        this.bikes.splice(this.bikes.indexOf(bike), 1)
      } else {
        this.bikes.push(bike)        
      }
    },

    isSelected (bike) {
      return find(this.bikes, { id: bike.id }) ? true : false
    },

    isDisabled (bike) {
      if (this.collection) {
        return !bike.available_collection_statuses.includes('STATUS_COLLECTED')
      }
      return !bike.available_return_statuses.includes('STATUS_RETURNED')
    },

    markCollected () {
      if (this.bikes.length > 0) {
        this.error = null
        this.loading = true
        var requests = []
        this.bikes.forEach(bike => {
          requests.push({
            path: `bookings/${this.booking.id}/bikes/${bike.id}`,
            object: {
              ...bike,
              ...this.updateStatusObject
            }
          })
        })
        this.$api.persistMultiple('put', requests)
          .then(() => {
            this.$refs.formErrors.clear()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
          })
          .finally(() => this.loading = false)
      } else {
        this.error = 'Please choose at least 1 bike.'
        this.$refs.errorBar.open()
      }
    }
  }
}
</script>
