<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary text-white">
          <span class="headline">Charge Payment Method</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="form.booking_bike_id"
                  :rules="[v => !!v || 'Bike is required']"
                  :items="booking.bikes"
                  :item-text="bike => { return `${bike.manafacturer} ${bike.model} (${bike.ref})` }"
                  :disabled="booking.bikes.length === 1"
                  item-value="id"
                  label="Bike"
                  hint="The bike that this payment link is related to."
                  outlined
                  required
                  autofocus
                  dense
                />
              </v-col>
            </v-row>
            <v-divider class="mb-5" />
            <v-select
              v-model="form.payment_method"
              :items="booking.customer.payment_methods"
              :item-text="paymentMethod => { return `${paymentMethod.card_brand.toUpperCase()} - ${paymentMethod.card_exp_last4}` }"
              :disabled="booking.customer.payment_methods.length === 0"
              item-value="id"
              dense
              outlined
            />
            <v-divider class="mb-5" />
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.amount"
                  :rules="[v => !!v || 'Amount is required']"
                  type="number"
                  step="0.1"
                  min="0"
                  prefix="£"
                  label="Amount"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <v-textarea
                  v-model="form.description"
                  :rules="[v => !!v || 'Description is required']"
                  rows="2"
                  label="Description"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-alert
              dense
              outlined
              color="primary"
            >
              Please have the customer <strong>verbally</strong> confirm that you have their permission to charge their payment method £{{ form.amount }}.
              <v-checkbox
                v-model="form.confirm_with_customer"
                label="Confirmed With Customer?"
              />
            </v-alert>

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" text @click="chargePaymentMethod" :disabled="!form.confirm_with_customer" :loading="loading">
            Charge Payment Method <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Payment taken successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ error.message }}
    </SnackBar>

  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasBooking from '@/mixins/HasBooking'
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'ChargePaymentDialog',

  mixins: [ HasDialog, HasBooking, HasForm ],

  components: {
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false,
      error: {}
    }
  },

  watch: {
    dialog: {
      handler: function (dialog) {
        if (this.oneBike && dialog) {
          this.$set(this.form, 'booking_bike_id', this.booking.bikes[0].id)
          this.$set(this.form, 'amount', Math.abs(this.bike.balance || this.booking.balance).toFixed(2))
        }
      },
      immediate: true
    }
  },

  mounted () {
    if(this.booking.customer.payment_methods[0]) {
      this.$set(this.form, 'payment_method', this.booking.customer.payment_methods[0].id)
    }
  },

  computed: {
    oneBike () {
      return this.booking.bikes.length === 1
    },

    bike () {
      var selected = {
        balance: 0
      }
      if (this.form.booking_bike_id) {
        this.booking.bikes.forEach(bike => {
          if (bike.id === this.form.booking_bike_id) {
            selected = bike
          }
        })
      }
      return selected
    }
  },

  methods: {
    chargePaymentMethod () {
      this.$refs.formErrors.clear()
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.persist('post', {
          path: `payment-methods/${this.form.payment_method}/charge`,
          object: this.form
        })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
            this.$set(this.form, 'payment_method', this.booking.customer.payment_methods[0].id)
          })
          .catch(error => {
            this.error = error
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => this.loading = false)
      }
    }
  }
}
</script>
