<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Add Bike</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <h3 class="mb-4 primary--text">1. Job Info</h3>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="form.job_type"
                  :rules="[v => !!v || 'Job type is required']"
                  :items="jobTypes"
                  label="Job Type"
                  item-text="label"
                  item-value="value"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <AlgoliaSearch
                  v-model="form.asset"
                  ref="algoliaSearch"
                  label="Asset"
                  index="assets"
                  item-text="number"
                  item-value="id"
                >
                  <template slot-scope="data">
                    <v-list-item-content>
                      <v-list-item-title class="search_highlight"><strong v-html="data.item._highlightResult.number.value" /></v-list-item-title>
                      <v-list-item-subtitle class="search_highlight" v-html="data.item._highlightResult.manafacturer.value" />
                      <v-list-item-subtitle class="search_highlight" v-html="data.item._highlightResult.assetable_name.value" />
                    </v-list-item-content>
                  </template>
                </AlgoliaSearch>
              </v-col>
            </v-row>

            <v-divider class="mb-6" />

            <h3 class="mb-4 primary--text">2. Service Info</h3>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.service_sku"
                  :rules="[v => !!v || 'Service is required']"
                  :items="services"
                  :item-text="service => { return `${service.name} (${service.sku}) - ${$currency(service.price)}` }"
                  item-value="sku"
                  label="Service"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-divider class="mb-6" />

            <h3 class="mb-4 primary--text">3. Bike Info</h3>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.manafacturer"
                  :rules="[v => !!v || 'Manufacturer is required']"
                  label="Manufacturer"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.model"
                  :rules="[v => !!v || 'Model is required']"
                  label="Model"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.colour"
                  label="Colour"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-if="services[0]"
                  v-model="form.type"
                  :rules="[v => !!v || 'Type is required']"
                  :items="services[0].available_types"
                  item-text="value"
                  item-value="key"
                  label="Type"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-divider class="mb-6" />

            <h3 class="mb-4 primary--text">4. Customer Info</h3>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.additional_authorised_spend"
                  type="number"
                  step="0.01"
                  min="0"
                  prefix="£"
                  label="Authorised Spend"
                  hint="The amount of money the customer has authorised to spend"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <v-textarea
                  v-model="form.customer_notes"
                  rows="2"
                  label="Customer Notes"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>

          <FormErrors ref="formErrors" />

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" text @click="createBike" :loading="loading">Add Bike <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Bike created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the bike.
    </SnackBar>

  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import AlgoliaSearch from '@/components/algolia-search/AlgoliaSearch'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'AddBikeDialog',

  mixins: [ HasBooking, HasDialog, HasForm ],

  components: {
    AlgoliaSearch,
    FormErrors,
    SnackBar
  },

  data () {
    return {
      form: {
        job_type: 'JOB_TYPE_C&R',
        unknown_details: false,
        additional_authorised_spend: 0
      },
      jobTypes: [],
      services: [],
      loading: false,
      error: {}
    }
  },

  mounted () {
    this.getServices()
    this.getJobTypes()
  },

  watch: {
    'form.asset': function (asset) {
      if (asset) {
        if (asset.manafacturer) {
          this.$set(this.form, 'manafacturer', asset.manafacturer)
        }
        if (asset.model) {
          this.$set(this.form, 'model', asset.model)
        }
        if (asset.colour) {
          this.$set(this.form, 'colour', asset.colour)
        }
      }
    }
  },

  methods: {
    getServices () {
      this.$api.get({
        path: 'services',
        params: {
          orderBy: 'price',
          ascending: 1,
          limit: 100,
          show_all: true
        }
      })
        .then(({ data }) => {
          this.services = data
        })
    },

    getJobTypes () {
      this.$api.get({
        path: 'job-types'
      })
        .then(types => {
          Object.keys(types).forEach((key) => {
            this.jobTypes.push({
              label: types[key],
              value: key
            })
          })
        })
    },

    createBike () {
      this.error = {}
      this.loading = true

      this.$api.persist('post', {
        path: `bookings/${this.booking.id}/bikes`,
        object: {
          ...this.form,
          ...{
            asset_id: this.form.asset ? this.form.asset.id : null
          }
        }
      })
        .then(() => {
          this.$refs.successBar.open()
          this.closeDialog()
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
          this.error = error
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
