<template>
  <div class="pt-4">
    <v-textarea
      v-model="note.content"
      :background-color="getNoteClasses(note.type, 'background')"
      :color="getNoteClasses(note.type, 'text')"
      label="Note"
      class="mt-0 pt-0 note-text"
      rows="3"
      outlined
      filled
      auto-grow
    />

    <v-alert
      :value="note.type === 'TYPE_CUSTOMER_NOTE'"
      type="warning"
      style="margin-top: -20px"
    >
      This note will be sent to the customer by e-mail.
    </v-alert>

    <FormErrors ref="formErrors" class="mt-0 mb-10" />

    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-select
          v-model="note.type"
          style="max-width: 200px;"
          class="mb-0 pb-0"
          :items="types"
          dense
          outlined
          label="Note Type"
        />
        <v-btn
          color="success"
          class="ml-2 py-5"
          depressed
          :disabled="!note.content"
          :loading="loading"
          @click="createNote"
        >
          Add Note
        </v-btn>
      </v-col>
    </v-row>

    <SnackBar success ref="successBar">
      Note created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the note.
    </SnackBar>
  </div>
</template>
<script>
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'
import HasNotes from '@/mixins/HasNotes'

export default {
  name: 'NewNote',

  mixins: [ HasNotes ],

  props: {
    recordId: {
      type: String,
      required: true
    },
    booking: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },
    bookingBike: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  components: {
    FormErrors,
    SnackBar
  },

  data () {
    return {
      loading: false,
      noteTypes: [],
      note: {
        type: 'TYPE_WORKSHOP_NOTE',
        content: ''
      }
    }
  },

  mounted () {
    this.getNoteTypes()
  },

  computed: {
    types () {
      var types = []
      Object.keys(this.noteTypes).forEach(key => {
        types.push({
          text: this.noteTypes[key],
          value: key
        })
      })
      return types
    },

    noteableType () {
      if (this.booking) {
        return 'BOOKING'
      }
      if (this.bookingBike) {
        return 'BOOKING_BIKE'
      }
      return null
    }
  },

  methods: {
    getNoteTypes () {
      this.$api.get({
        path: 'note-types'
      })
        .then(data => {
          this.noteTypes = data
          this.note.type = Object.keys(this.noteTypes)[0]
        })
    },

    createNote () {
      this.loading = true
      this.$refs.formErrors.clear()
      
      this.$api.persist('post', {
        path: 'notes',
        object: {
          ...this.note,
          ...{ noteable_id: this.recordId, noteable_type: this.noteableType },
        }
      })
        .then(() => {
          this.$refs.successBar.open()
          this.note = {}
          this.note.type = Object.keys(this.noteTypes)[0]
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
<style>
  .theme--dark.v-textarea.note-text textarea,
  .theme--dark.v-textarea.note-text textarea::placeholder {
    color: #000000;
  }
  .theme--dark.v-textarea.note-text label.v-label {
    color: #000000;
  }
</style>
