<template>
  <div>
    <v-alert
      v-if="error.message"
      dense
      text
      outlined
      class="mt-2"
      type="error"
    >
      {{ error.message }}
    </v-alert>

    <NewNote
      booking
      :record-id="booking.id"
    />

    <v-divider class="mb-4"/>

    <v-card
      :loading="loading"
      outlined
    >
      <v-card-text>
        <v-timeline
          v-if="timelineItems.length > 0"
          v-user-can:READ_BOOKINGS_TIMELINE
          class="mt-2"
          dense
          clipped
        >
          <v-timeline-item
            v-for="timelineItem in timelineItems"
            :key="timelineItem.id"
            right
            small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>{{ $momentDateTimeHuman(timelineItem.created_at) }}</strong>
              </v-col>
              <v-col>
                <div v-if="timelineItem.communication">
                  <BookingTimelineItemEmail
                    v-if="timelineItem.communication.type === 'TYPE_EMAIL'"
                    :timeline-item="timelineItem"
                  />
                  <BookingTimelineItemSMS
                    v-if="timelineItem.communication.type === 'TYPE_SMS'"
                    :timeline-item="timelineItem"
                  />
                  <BookingTimelineItemPhone
                    v-else-if="timelineItem.communication.type === 'TYPE_PHONE'"
                    :timeline-item="timelineItem"
                  />
                </div>
                <BookingTimelineItemNote
                  v-if="timelineItem.type === 'TYPE_NOTE'"
                  :timeline-item="timelineItem"
                />
                <BookingTimelineItemModelUpdate
                  v-if="timelineItem.type === 'MODEL_UPDATE'"
                  :timeline-item="timelineItem"
                />
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>

        <v-alert
          v-user-cant:READ_BOOKINGS_TIMELINE
          dense
          text
          outlined
          type="warning"
        >
          You don't have permission to view the timeline.
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import BookingTimelineItemEmail from '@/components/bookings/BookingTimelineItemEmail'
import BookingTimelineItemPhone from '@/components/bookings/BookingTimelineItemPhone'
import BookingTimelineItemNote from '@/components/bookings/BookingTimelineItemNote'
import BookingTimelineItemSMS from '@/components/bookings/BookingTimelineItemSMS'
import BookingTimelineItemModelUpdate from '@/components/bookings/BookingTimelineItemModelUpdate'
import NewNote from '@/components/notes/NewNote'

export default {
  name: 'BookingTimeline',

  mixins: [ HasBooking ],

  components: {
    BookingTimelineItemEmail,
    BookingTimelineItemPhone,
    BookingTimelineItemNote,
    BookingTimelineItemModelUpdate,
    BookingTimelineItemSMS,
    NewNote
  },

  data () {
    return {
      timelineItems: [],
      error: {},
      loading: false
    }
  },

  mounted () {
    this.getTimeline()

    if (window.Echo) {
      window.Echo.private(`${this.booking.id}.timeline-items`)
        .listen('TimelineItemCreated', () => {
          this.getTimeline()
        })
    }
  },

  methods: {
    reload () {
      this.getTimeline()
    },

    getTimeline () {
      this.loading = true
      this.$api.get({
        path: `bookings/${this.booking.id}/timeline`,
        params: {
          with: ['linkable', 'createdBy'],
          orderBy: 'created_at',
          limit: 100
        }
      })
        .then(({ data: timelineItems }) => {
          this.timelineItems = timelineItems
          this.error = {}
        })
        .catch(error => this.error = error)
        .finally(() => this.loading = false)
    }
  }
}
</script>
