<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Re-Arrange Dates - {{ bike.ref }}</span>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-alert
              dense
              outlined
              color="primary"
            >
              <v-checkbox
                v-model="form.reschedule_collection"
                label="Reschedule collection date?"
              />
              <v-checkbox
                v-model="form.reschedule_return"
                label="Reschedule return date?"
              />
            </v-alert>

            <v-alert v-if="form.reschedule_collection" dense type="info" outlined text>
              Please remember to update the TBO date if the collection date is being moved forward - <strong>this can be edited in the <u>workshop</u> tab</strong>
            </v-alert>

            <v-row>
              <v-col
                v-if="form.reschedule_collection"

                cols="12"
                sm="6"
              >
                <v-dialog
                  ref="collectionDate"
                  v-model="collectionDateModal"
                  :return-value.sync="form.rearranged_collection_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="form.rearranged_collection_date ? $moment(form.rearranged_collection_date).format('ddd Do MMM') : null"
                      :rules="[v => !!v || 'New collection date is required']"
                      class="mt-4"
                      label="New Collection Date"
                      outlined
                      dense
                      readonly
                      required
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="form.rearranged_collection_date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="collectionDateModal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.collectionDate.save(form.rearranged_collection_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-checkbox
                  v-model="form.send_reschedule_collection_notification"
                  label="Send reschedule collection notification to customer?"
                />
              </v-col>
              <v-col
                v-if="form.reschedule_return"
                cols="12"
                sm="6"
              >
                <v-dialog
                  ref="returnDate"
                  v-model="returnDateModal"
                  :return-value.sync="form.rearranged_return_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="$moment(form.rearranged_return_date).format('ddd Do MMM')"
                      :rules="[v => !!v || 'New return date is required']"
                      class="mt-4"
                      label="New Return Date"
                      readonly
                      outlined
                      dense
                      required
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="form.rearranged_return_date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="returnDateModal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.returnDate.save(form.rearranged_return_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-checkbox
                  v-model="form.send_return_collection_notification"
                  label="Send reschedule return notification to customer?"
                />
              </v-col>
            </v-row>
            <v-divider v-if="form.reschedule_return || form.reschedule_collection" class="mb-6 mt-2" />
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="form.reschedule_reason"
                  :rules="[v => !!v || 'Reason is required']"
                  :items="reasons"
                  label="Reschedule Reason"
                  item-text="label"
                  item-value="value"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-textarea
                  v-model="form.reschedule_notes"
                  :rules="[v => !!v || 'Re-Schedule notes are required']"
                  rows="2"
                  label="Re-Schedule Notes (Internal)"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn
            :disabled="!(form.reschedule_collection || form.reschedule_return)"
            :loading="loading"
            color="primary"
            text
            @click="updateBooking"
          >
            Re-Arrange Dates <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar error ref="errorBar">
      There was an error re-arranging dates on this job!
    </SnackBar>
    <SnackBar success ref="successBar">
      Job dates re-arranged successfully!
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasBike from '@/mixins/HasBike'
import HasBooking from '@/mixins/HasBooking'
import HasForm from '@/mixins/HasForm'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'BikeReArrangeDatesDialog',

  mixins: [ HasDialog, HasBike, HasBooking, HasForm ],

  components: {
    FormErrors,
    SnackBar
  },

  data () {
    return {
      collectionDateModal: false,
      returnDateModal: false,
      form: {
        reschedule_collection: false,
        reschedule_return: false
      },
      loading: false,
      reasons: [
        {
          label: 'Awaiting Parts',
          'value': 'REASON_AWAITING_PARTS'
        },
        {
          label: 'Customer Request',
          'value': 'REASON_CUSTOMER_REQUEST'
        },
        {
          label: 'Workshop Resource',
          'value': 'REASON_WORKSHOP_RESOURCE'
        },
        {
          label: 'Logistics',
          'value': 'REASON_LOGISTICS'
        },
        {
          label: 'Other (Add Notes)',
          'value': 'REASON_OTHER'
        }
      ]
    }
  },

  watch: {
    dialog: function () {
      if (this.bike.rearranged_collection_date) {
        this.form = {
          ...this.form,
          ...{ rearranged_collection_date: this.$moment(this.bike.rearranged_collection_date).format('YYYY-MM-DD') }
        }
      }

      if (this.bike.rearranged_return_date) {
        this.form = {
          ...this.form,
          ...{ rearranged_return_date: this.$moment(this.bike.rearranged_return_date).format('YYYY-MM-DD') }
        }
      }
    }
  },

  methods: {
    updateBooking () {
      this.$refs.formErrors.clear()

      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.persist('put', {
          path: `bookings/${this.booking.id}/bikes/${this.bike.id}/dates/cr`,
          object: this.form
        })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    }
  }
}
</script>
