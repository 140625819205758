<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Schedule TBO Date</span>
        </v-card-title>
        <v-card-text>
          <v-form
            v-model="validForm"
            ref="form"
          >
            <TboMetricsSummary
              title=""
              :end="$momentDateFormat($moment().add(14, 'days'))"
              :exclude-workshop-status="['STATUS_CANCELLED', 'STATUS_DISPATCHED', 'STATUS_TRIAGE_NO_CONTACT']"
            />

            <v-divider class="mt-6" />

            <v-row class="mt-2">
              <v-col :cols="12" :md="6" class="pb-0">
                <v-dialog
                  ref="collectionDate"
                  v-model="dateModal"
                  :return-value.sync="form.planned_service_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="form.planned_service_date ? $moment(form.planned_service_date).format('ddd Do MMM') : null"
                      class="mt-4"
                      label="TBO Date"
                      outlined
                      dense
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="form.planned_service_date"
                    :min="$moment().format('YYYY-MM-DD')"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateModal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.collectionDate.save(form.planned_service_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="form.reschedule_reason"
                  :rules="[v => !!v || 'Reason is required']"
                  :items="reasons"
                  label="Reschedule Reason"
                  item-text="label"
                  item-value="value"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-textarea
                  v-model="form.reschedule_notes"
                  :rules="[v => !!v || 'Re-Schedule notes are required']"
                  rows="2"
                  label="Re-Schedule Notes (Internal)"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="updateBike"
          >
            Update TBO Date <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      TBO date updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the TBO date.
    </SnackBar>
  </div>
</template>
<script>
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'
import HasDialog from '@/mixins/HasDialog'
import HasBike from '@/mixins/HasBike'
import TboMetricsSummary from '@/components/dashboard/TboMetricsSummary'

export default {
  name: 'ScheduleTBWDateDialog',

  mixins: [ HasDialog, FormErrors, HasBike ],

  components: {
    FormErrors,
    SnackBar,
    TboMetricsSummary
  },

  data () {
    return {
      loading: false,
      dateModal: false,
      validForm: true,
      form: {},
      error: {},
      reasons: [
        {
          label: 'Awaiting Parts',
          'value': 'REASON_AWAITING_PARTS'
        },
        {
          label: 'Customer Request',
          'value': 'REASON_CUSTOMER_REQUEST'
        },
        {
          label: 'Workshop Resource',
          'value': 'REASON_WORKSHOP_RESOURCE'
        },
        {
          label: 'Logistics',
          'value': 'REASON_LOGISTICS'
        },
        {
          label: 'Other (Add Notes)',
          'value': 'REASON_OTHER'
        }
      ],
      postcodeError: {
        errors: {
          postcode: []
        }
      }
    }
  },

  watch: {
    dialog: function () {
      if (this.bike.planned_service_date) {
        this.form = {
          ...this.form,
          ...{ planned_service_date: this.$moment(this.bike.planned_service_date).format('YYYY-MM-DD') }
        }
      }
    }
  },

  methods: {
    updateBike () {
      this.$refs.formErrors.clear()

      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.persist('put', {
          path: `bookings/${this.bike.booking_id}/bikes/${this.bike.id}/dates/tbo`,
          object: this.form
        })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    }
  }
}
</script>
