<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Edit {{ isCollection ? 'Collection' : 'Return' }} Address</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-alert type="info" outlined text dense>
              Editing the {{ isCollection ? 'collection' : 'return' }} address will effect all bikes in this booking. If you need to change only 1 bike, please move the bike to it's own booking.
            </v-alert>
            <v-row>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="postcode"              
                  :disabled="addressData.addresses ? true : false"
                  label="Postcode"
                  :rules="[v => !!v || 'Please choose a postcode']"
                  required
                  outlined
                  dense
                  autofocus />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-0">
                <v-btn
                  v-if="addressData.addresses ? false : true"
                  :loading="loading"
                  color="primary"
                  @click="checkAvailability">
                  Search Addresses
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  v-if="addressData.addresses"
                  v-model="address"
                  :items="addressData.addresses"
                  :rules="[v => !!v || 'Please choose an address']"
                  required
                  label="Address"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-alert
              v-if="addressData.addresses"
              dense
              outlined
              color="primary"
            >
              <v-checkbox
                v-if="isCollection"
                v-model="return_to_same_address"
                label="Also change return address?"
              />

              <v-checkbox
                v-model="send_change_notification"
                label="Send address change notification to customer?"
              />
            </v-alert>

            <v-alert
              v-if="postcodeError.errors.postcode.length > 0"
              outlined
              border="top"
              type="error"
            >
              {{ postcodeError.errors.postcode[0] }}
            </v-alert>

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="!addressData.addresses"
            color="primary"
            text
            @click="updateBooking"
          >
            Update Booking <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Booking updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the address.
    </SnackBar>
  </div>
</template>
<script>
import HasDialog from '@/mixins/HasDialog'
import HasBooking from '@/mixins/HasBooking'
import HasBike from '@/mixins/HasBike'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'
import HasCollectionOrReturn from '@/mixins/HasCollectionOrReturn'

export default {
  name: 'EditAddressDialog',

  mixins: [ HasDialog, HasBooking, FormErrors, HasCollectionOrReturn, HasBike ],

  components: {
    FormErrors,
    SnackBar
  },

  data () {
    return {
      return_to_same_address: false,
      loading: false,
      postcode: null,
      address: null,
      validForm: true,
      send_change_notification: false,
      addressData: {},
      error: {},
      postcodeError: {
        errors: {
          postcode: []
        }
      }
    }
  },

  methods: {
    checkAvailability () {
      this.loading = true
      this.$api.persist('post', {
          path: 'availability',
          object: {
            postcode: this.postcode
          }
        }
      )
        .then(({ data }) => {
          this.addressData = {
            postcode_data: data.postcode_data,
            collection_days: data.collection_days,
            return_days: data.return_days,
            addresses: data.addresses
          }
          this.postcodeError = {
            errors: {
              postcode: []
            }
          }
        })
        .catch(error => {
          this.addressData = {}
          this.postcodeError = error
        })
        .finally(() => { this.loading = false })
    },

    updateBooking () {
      this.$refs.formErrors.clear()

      if (this.$refs.form.validate()) {
        this.loading = true

        this.$api.persist('put', {
          path: `bookings/${this.booking.id}/bikes/${this.bike.id}/addresses/${this.isCollection ? 'collection' : 'return'}`,
          object: {
            postcode: this.postcode,
            address: this.address,
            return_to_different_address: !this.return_to_same_address,
            send_change_notification: this.send_change_notification || false
          }
        })
          .then(() => {
            this.$refs.successBar.open()
            this.error = {}
            this.addressData = {}
            this.postcode = null
            this.address = null
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
            this.error = error
          })
          .finally(() => this.loading = false)
      }
    }
  }
}
</script>
