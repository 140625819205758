<template>
  <div>
    <v-alert
      dense
      outlined
      color="primary"
    >
      <v-row>
        <v-col :cols="6">
          <h2>{{ question.title }}</h2>
          <p>{{ question.subtitle }}</p>
        </v-col>
        <v-col :cols="3">
          <v-checkbox
            v-model="localValue"
            :value="question.value"
            label="Complete"
          />
        </v-col>
        <v-col :cols="3">
          <v-checkbox
            v-model="localValue"
            :value="question.value"
            disabled
            label="Complete"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-model="localValue"
            label="Notes"
            rows="2"
            outlined
            dense
          />
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'BookingGCQuestionCard',

  mixins: [ HasVModel ],

  props: {
    question: {
      type: Object,
      required: true
    }
  }
}
</script>
