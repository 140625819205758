<template>
  <div>
    <div v-user-can:READ_BOOKINGS_C_R>
      <v-row>
        <v-col>
          <BookingCRCard
            :booking="booking"
            title="Collection"
            date-prop="collection_date"
            address-prop="collection_address"
            address-postcode-prop="collection_postcode"
          >
            <template v-slot:action-btn>
              <v-btn
                color="primary"
                depressed
                small
                @click="collectionDialog = true"
              >
                Mark Collected
                <v-icon
                  right
                  dense
                >
                  mdi-calendar-check
                </v-icon>
              </v-btn>

              <BookingCRTable
                :booking="booking"
                collection
              />

              <BookingCRDialog
                :booking="booking"
                :dialog.sync="collectionDialog"
                collection
              />
            </template>
          </BookingCRCard>
        </v-col>
      </v-row>

      <v-divider class="mt-2 mb-2" />

      <v-row>
        <v-col>
          <BookingCRCard
            :booking="booking"
            title="Return"
            date-prop="return_date"
            address-prop="return_address"
            address-postcode-prop="return_postcode"
          >
            <template v-slot:action-btn>
              <v-btn
                color="primary"
                depressed
                small
                @click="returnDialog = true"
              >
                Mark Returned
                <v-icon
                  right
                  dense
                >
                  mdi-calendar-check
                </v-icon>
              </v-btn>

              <BookingCRTable
                :booking="booking"
                return
              />

              <BookingCRDialog
                :booking="booking"
                :dialog.sync="returnDialog"
                return
              />
            </template>
          </BookingCRCard>
        </v-col>
      </v-row>

      <v-alert
        v-if="booking.driver_notes.length > 0"
        v-user-can:READ_BOOKINGS_TIMELINE
        :loading="loading"
        class="mt-4"
        type="warning"
        outlined
      >
        <v-card-text>
          <v-timeline
            class="mt-2"
            dense
            clipped
          >
            <v-timeline-item
              v-for="note in booking.driver_notes"
              :key="note.id"
              right
              small
            >
              <v-row class="pt-1">
                <v-col cols="3">
                  <strong>{{ $momentDateTimeHuman(note.created_at) }}</strong>
                </v-col>
                <v-col>
                  <NoteBox :data="note">
                    {{ note.content }}
                    <template #footer>
                      <small class="d-block text-right font-weight-medium mt-2">
                        Noted By: {{ note.created_by.name }}
                        <v-avatar size="20" color="primary">
                          <img
                            :src="note.created_by.profile_img_url"
                            :alt="note.created_by.name">
                        </v-avatar>
                      </small>
                    </template>
                  </NoteBox>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-alert>
    </div>
    <v-alert
      v-user-cant:READ_BOOKINGS_C_R
      dense
      text
      outlined
      type="warning"
    >
      You don't have permission to view the C&R section.
    </v-alert>
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import BookingCRCard from '@/components/bookings/BookingCRCard'
import BookingCRDialog from '@/components/bookings/BookingCRDialog'
import BookingCRTable from '@/components/bookings/BookingCRTable'
import NoteBox from '@/components/notes/NoteBox'

export default {
  name: 'BookingCR',

  mixins: [ HasBooking ],

  components: {
    BookingCRCard,
    BookingCRDialog,
    BookingCRTable,
    NoteBox
  },

  data () {
    return {
      collectionDialog: false,
      returnDialog: false,
      loading: false
    }
  }
}
</script>
