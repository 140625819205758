<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <span class="headline">{{ title }}</span>
        <v-btn
          v-user-can="canUpdate"
          small
          text
          class="ml-2"
          color="primary"
          @click="dialog = true"
        >
          Edit
        </v-btn>
        <EditAddressDialog
          :collection="addressPostcodeProp === 'collection_postcode'"
          :booking="booking"
          :bike="booking.bikes[0]"
          :dialog.sync="dialog"
        />

        <v-spacer></v-spacer>

<!--         <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>Re-Schedule</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-card-title>

      <v-card-text>
        <p class="mb-0">Scheduled For: <strong>{{ $momentDate(booking[dateProp]) }}</strong></p>
        <p>
          {{ booking[addressProp] }}
          <a
            :href="`https://www.google.com/maps/place/${booking[addressPostcodeProp]}`"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-icon
              right
              dense
            >
              mdi-map-search
            </v-icon>
          </a>
        </p>
      
        <slot name="action-btn" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import EditAddressDialog from '@/components/bookings/EditAddressDialog'

export default {
  name: 'BookingCRCard',

  mixins: [ HasBooking ],

  components: {
    EditAddressDialog
  },

  props: {
    title: {
      type: String,
      required: true
    },
    dateProp: {
      type: String,
      required: true
    },
    addressProp: {
      type: String,
      required: true
    },
    addressPostcodeProp: {
      type: String,
      required: true
    }
  },

  computed: {
    canUpdate () {
      return this.addressPostcodeProp === 'collection_postcode' ? 'UPDATE_BIKES_COLLECTION_ADDRESS' : 'UPDATE_BIKES_RETURN_ADDRESS'
    }
  },

  data () {
    return {
      dialog: false
    }
  }
}
</script>
