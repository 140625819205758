export default {
  props: {
    collection: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },
    return: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  computed: {
    isReturn () {
      return this.return
    },

    isCollection () {
      return this.collection
    },

    markType () {
      if (this.collection) {
        return 'Collected'
      }
      return 'Returned'
    },

    forType () {
      if (this.collection) {
        return 'Collection'
      }
      return 'Return'
    }
  }
}