<template>
  <v-container fluid>
    <InnerPageBackHeader @back="$router.go(-1)">
      <template v-slot:right>
        <div class="d-flex justify-content-between align-items-center w-full">
          <div class="pt-2">
            <p class="mb-0 pb-0" style="line-height: 0.1;"><small><strong>Created At:</strong> {{ $momentDateTimeHuman(booking.created_at) }}</small></p>
            <p class="mb-0 mt-0 pt-0"><small><strong>Updated At:</strong> {{ $momentDateTimeHuman(booking.updated_at) }}</small></p>
          </div>
          <v-btn
            outlined
            dark
            small
            depressed
            color="primary"
            @click="getBooking(); reloadTab()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </template>
    </InnerPageBackHeader>

    <v-row dense class="mt-4">
      <!-- LEFT COL -->
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <v-row dense class="d-lg-none">
          <v-col
            :cols="12"
          >
            <v-card
              class="mb-2"
              outlined
            >
              <v-card-text>
                <BookingCustomerSummary
                  :booking="booking"
                />
              </v-card-text>
            </v-card>

            <v-card outlined class="mb-2">
              <v-card-text>
                <BookingFinanceSummary
                  :booking="booking"
                />
              </v-card-text>
            </v-card>

            <v-card outlined class="mb-2">
              <v-card-text>
                <BookingServiceRecordSummary
                  :booking="booking"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-card
          :loading="loading"
        >
            <v-card outlined>
              <v-card-text>
                <BookingCustomerSummary
                  class="d-none d-lg-block"
                  :booking="booking"
                />
              </v-card-text>
            </v-card>

            <BikesList
              v-user-can:LIST_BIKES
              :booking="booking"
              class="mt-4"
            />

            <SundriesList
              v-user-can:LIST_BOOKING_SUNDRIES            
              :booking="booking"
              class="mt-4"
            />

            <PaymentsLists
              v-user-can:READ_PAYMENTS            
              :booking="booking"
              class="mt-4"
            />

            <PaymentLinksList
              v-user-can:READ_PAYMENT_LINKS          
              :booking="booking"
              class="mt-4"
            />
        </v-card>
      </v-col>

      <!-- MIDDLE COL -->
      <v-col
        cols="12"
        md="7"
        lg="5"
      >
        <InnerPageNavigation
          class="mb-2"
          :tabs="[
            { disabled: !$auth.userCan('READ_BOOKINGS_TIMELINE'), icon: 'mdi-timeline-text', label: 'Timeline', to: { name: 'bookings.timeline', params: { bookingId: this.bookingId }} },
            { disabled: !$auth.userCan('READ_BOOKINGS_C_R'), icon: 'mdi-truck-fast', label: 'C&R', to: { name: 'bookings.candr', params: { bookingId: this.bookingId }} },
            { disabled: !$auth.userCan('READ_BOOKINGS_WORKSHOP'), icon: 'mdi-wrench', label: 'Workshop', to: { name: 'bookings.workshop', params: { bookingId: this.bookingId }} },
            { disabled: false, icon: 'mdi-file-multiple', label: 'Files', to: { name: 'bookings.files', params: { bookingId: this.bookingId }} },
            { disabled: !$auth.userCan('READ_BOOKINGS_QC'), icon: 'mdi-alert-circle-check', label: 'QC', to: { name: 'bookings.qc', params: { bookingId: this.bookingId }} },
          ]"
        />
        <component
          v-if="booking.id"
          :ref="`tab${$route.meta.innerComponent || 'BookingTimeline'}`"
          :is="$route.meta.innerComponent || 'BookingTimeline'"
          :booking="booking"
        />
      </v-col>

      <!-- RIGHT COL -->
      <v-col
        class="d-none d-lg-block"
        cols="12"
        lg="3"
      >
        <v-card outlined>
          <v-card-text>
            <BookingFinanceSummary
              :booking="booking"
            />
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-2">
          <v-card-text>
            <BookingServiceRecordSummary
              :booking="booking"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script type="text/javascript">
import SundriesList from '@/components/sundries/SundriesList'
import BikesList from '@/components/bikes/BikesList'
import PaymentsLists from '@/components/payments/PaymentsLists'
import BookingFinanceSummary from '@/components/bookings/BookingFinanceSummary'
import BookingServiceRecordSummary from '@/components/bookings/BookingServiceRecordSummary'
import InnerPageNavigation from '@/components/InnerPageNavigation'
import BookingTimeline from '@/components/bookings/BookingTimeline'
import BookingCR from '@/components/bookings/BookingCR'
import BookingWorkshop from '@/components/bookings/BookingWorkshop'
import BookingQC from '@/components/bookings/BookingQC'
import BookingFiles from '@/components/bookings/BookingFiles'
import BookingCustomerSummary from '@/components/bookings/BookingCustomerSummary'
import InnerPageBackHeader from '@/components/InnerPageBackHeader'
import PaymentLinksList from '@/components/payments/PaymentLinksList'

export default {
  name: 'ShowBooking',

  props: {
    bookingId: {
      type: String,
      required: true
    }
  },

  components: {
    SundriesList,
    BikesList,
    PaymentsLists,
    BookingFinanceSummary,
    BookingCustomerSummary,
    InnerPageNavigation,
    BookingTimeline,
    BookingCR,
    BookingWorkshop,
    BookingQC,
    InnerPageBackHeader,
    PaymentLinksList,
    BookingServiceRecordSummary,
    BookingFiles
  },

  data () {
    return {
      loading: false,
      booking: {
        bikes: [],
        customer: {
          account: {}
        }
      }
    }
  },

  mounted () {
    this.setup()
  },

  watch: {
    bookingId: function () {
      this.booking = {
        bikes: [],
        customer: {
          account: {}
        }
      }
      this.setup()
    }
  },

  methods: {
    setup () {
      this.getBooking()

      if (window.Echo) {
        window.Echo.private(`bookings.${this.bookingId}`)
          .listen('BookingUpdated', () => {
            this.getBooking()
          })

        window.Echo.private(`${this.bookingId}.booking-bikes`)
          .listen('BookingBikeUpdated', () => {
            this.getBooking()
          })

        window.Echo.private(`${this.bookingId}.booking-bike-manifests`)
          .listen('BookingBikeManifestUpdated', () => {
            this.getBooking()
          })
      }
    },

    reloadTab () {
      if (this.$refs[`tab${this.$route.meta.innerComponent || 'BookingTimeline'}`]) {
        var ref = this.$refs[`tab${this.$route.meta.innerComponent || 'BookingTimeline'}`]
        if (typeof ref.reload == 'function') { 
          ref.reload() 
        }
      }
    },

    getBooking () {
      this.loading = true
      this.$api.get({
        path: `bookings/${this.bookingId}`,
        params: {
          with: [
            'bikes.service', 'bikes.serviceRecords.startedBy', 'bikes.serviceRecords.endedBy', 'bikes.manifests.pivot.crBy', 'bikes.manifests.driver', 'bikes.manifests.vehicle', 'bikes.sundries', 'bikes.payments', 'customer.paymentMethods', 'customer.account.parent', 'bikes.asset', 'driverNotes.createdBy', 'workshopNotes.createdBy'
          ]
        }
      })
        .then(({ data : booking }) => {
          this.booking = booking
        })
        .catch(() => {
          //
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
