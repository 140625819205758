export default {
  methods: {
    sundrySubtotal (sundry) {
      var subTotal = sundry.price

      sundry.associated_sundries.forEach(associatedSundry => {
        subTotal = subTotal + associatedSundry.price
      })

      return subTotal
    },

    getSundries () {
      this.loading = true
      this.$api.get({
        path: `bookings/${this.booking.id}/sundries`,
        params: {
          with: ['product', 'supplier', 'bookingBike.booking', 'associatedSundries'],
          onlyEstimates: true,
          noAssociated: true,
          noDeclined: true,
          bikeId: this.bike.id,
          orderBy: 'created_at',
          ascending: false,
          limit: 10 * 10 * 10
        }
      })
      .then(({ data: sundries}) => {
        this.sundries = sundries
      })
      .finally(() => this.loading = false)
    }
  }
}