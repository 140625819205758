<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Refund Payment</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.amount"
                  :rules="[v => !!v || 'Amount is required']"
                  autofocus
                  type="number"
                  step="0.1"
                  min="0"
                  prefix="£"
                  label="Refund Amount"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <v-textarea
                  v-model="form.reason"
                  :rules="[v => !!v || 'Reason is required']"
                  rows="2"
                  label="Refund Reason"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn :loading="loading" color="primary" text @click="refundPayment">Refund Payment <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Refund made successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      Error refunding payment!
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'RefundPaymentDialog',

  mixins: [ HasDialog, HasForm ],

  components: {
    SnackBar,
    FormErrors
  },

  props: {
    payment: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    refundPayment () {
      this.$refs.formErrors.clear()

      if (this.$refs.form.validate()) {
        this.loading = true

        this.$api.persist('post', {
          path: `payments/${this.payment.id}/refund`,
          object: {
            amount: this.form.amount,
            reason: this.form.reason
          }
        })
        .then(() => {
          this.$refs.successBar.open()
          this.closeDialog()
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
      }
    }
  }
}
</script>