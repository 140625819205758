<template>
  <v-row class="mt-2">
    <v-col>
      <v-select
        v-model="localValue"
        :items="orderedBikes"
        :disabled="booking.bikes.length === 1"
        item-value="id"
        dense
        outlined
      >
        <template v-slot:item="{ item: bike }">
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ bike.name }} {{ bike.asset ? '- ' + bike.asset.number : null }}</strong>
              <v-icon v-if="bike.job_type === 'JOB_TYPE_C&R_COMPLAINT'" color="red darken-2" small right>mdi-exclamation-thick</v-icon>
              <small class="red--text darken-2">{{ bike.job_type === 'JOB_TYPE_C&R_COMPLAINT' ? '[Complaint]' : '' }}</small>
            </v-list-item-title>
            <v-list-item-subtitle class="text--primary">{{ bike.workshop_status_label }}</v-list-item-subtitle>
            <v-list-item-subtitle>TBO: {{ $momentDate(bike.planned_service_date) }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text>{{ bike.ref }}</v-list-item-action-text>
          </v-list-item-action>
        </template>
        <template v-slot:selection="{ item: bike }">
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ bike.name }}</strong>
              <v-icon v-if="bike.job_type === 'JOB_TYPE_C&R_COMPLAINT'" color="red darken-2" small right>mdi-exclamation-thick</v-icon>
              <small class="red--text darken-2">{{ bike.job_type === 'JOB_TYPE_C&R_COMPLAINT' ? '[Complaint]' : '' }}</small>
            </v-list-item-title>
            <v-list-item-subtitle class="text--primary">{{ bike.ref }} {{ bike.asset ? '- ' + bike.asset.number : null }}</v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary">{{ bike.workshop_status_label }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import orderBy from 'lodash.orderby'
import filter from 'lodash.filter'

export default {
  name: 'BookingBikeSelector',

  mixins: [ HasBooking ],

  props: {
    value: {
      required: true
    }
  },

  mounted () {
    if (this.$route?.query?.job_number) {
      var selectedBike = filter(this.booking.bikes, (bike) => {
        return bike.ref === parseInt(this.$route.query.job_number)
      }) 
      if (selectedBike[0]) {
        this.localValue = selectedBike[0].id
      }
    }
  },

  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (localValue) {
        window.localStorage.setItem(`hb_booking_${this.booking.id}_bike`, localValue)
        this.$emit('input', localValue)
      }
    },

    orderedBikes () {
      return orderBy(this.booking.bikes, 'ref', 'desc')
    }
  }
}
</script>
