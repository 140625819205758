<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="red lighten-1 white--text">
          <span class="headline">Warranty Details</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col sm="12" cols="12">
                <v-checkbox
                  v-model="form.use_sundry"
                  :label="`Use ${sundry.name} to create warranty?`"
                />
              </v-col>
            </v-row>
            <v-row v-if="! form.use_sundry">
              <v-col sm="12" cols="12">
                <SundryAlgoliaSearch
                  v-model="form.warranty_item"
                  :bike="bike"
                  :labour="false"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" cols="12">
                <v-textarea
                  v-model="form.description"
                  label="Warranty Details (Internal)"
                  hint="Please explain why this part is being replaced under warranty."
                  autofocus
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row class="mb-12">
              <v-col sm="12" cols="12">
                <FileUpload
                  v-model="files"
                  multiple
                />
              </v-col>
            </v-row>
          </v-form>

          <FormErrors ref="formErrors" />

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog(); $emit('canceled')">Cancel</v-btn>
          <v-btn color="red" :loading="loading" text @click="createWarranty">Submit Warranty <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Warranty request created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the warranty request.
    </SnackBar>
  </div>
</template>
<script>
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import SundryAlgoliaSearch from '@/components/sundries/SundryAlgoliaSearch'
import FormErrors from '@/components/FormErrors'
import FileUpload from '@/components/file-upload/FileUpload'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'WarrantySundryDialog',

  mixins: [ HasDialog, HasForm ],

  components: {
    SundryAlgoliaSearch,
    FormErrors,
    FileUpload,
    SnackBar,
  },

  props: {
    bike: {
      type: Object,
      required: true
    },

    sundry: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: {
        use_sundry: true
      },
      loading: false,
      files: [],
      error: {}
    }
  },

  methods: {
    closeDialog () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      if (this.$refs.formErrors) {
        this.$refs.formErrors.clear()
      }
      this.form = {
        use_sundry: true
      }
      this.$emit('update:dialog', false)
    },

    createWarranty () {
      this.loading = true
      this.$refs.formErrors.clear()
      
      this.$api.persist('post', {
        path: 'warranties',
        object: {
          ...this.form,
          ...{
            warranty_files: this.files,
            item_sku: this.form.use_sundry ? this.sundry.sku : (this.form.warranty_item ? this.form.warranty_item.sku : null)
          }
        }
      })
        .then(({ data: warranty }) => {
          this.$refs.successBar.open()
          this.closeDialog()
          this.$emit('warranty:created', warranty)
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>