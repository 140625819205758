<template>
  <div>
    <div>
      <strong>{{ timelineItem.subject }}</strong>
      <div v-if="timelineItem.communication.channel === 'HELPSCOUT'" class="caption">
        <a v-if="timelineItem.communication.url" :href="timelineItem.communication.url">View in Helpscout</a>
        <span v-else>Helpscout</span>
      </div>
    </div>

    <v-dialog
      v-model="dialog"
      scrollable
      width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="mt-2"
          color="primary"
          outlined
          x-small
        >
          View Email
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          <strong class="mr-2">Subject:</strong> {{ timelineItem.subject }}
        </v-card-title>

        <v-card-text class="pt-4">
          <iframe :src="timelineItem.content_view_url" width="100%" height="800px" frameborder="0" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <p v-if="timelineItem.created_by" class="font-weight-medium text--disabled mt-2" style="font-size: 12px;">
      Email Sent By: {{ timelineItem.created_by.name }}
      <v-avatar size="20" color="primary">
        <img
          v-if="timelineItem.created_by.profile_img_url"
          :src="timelineItem.created_by.profile_img_url"
          :alt="timelineItem.created_by.name">
        <strong v-else class="white--text" style="font-size: 10px;">{{ timelineItem.created_by.initials }}</strong>
      </v-avatar>
    </p>

    <p v-if="timelineItem.communication.read_at" class="font-weight-medium text--disabled mt-2" style="font-size: 12px;">
      <v-icon small>mdi-eye</v-icon>
      Read At: {{ $momentDateTimeHuman(timelineItem.communication.read_at) }}
    </p>
  </div>
</template>
<script>
import HasTimelineItem from '@/mixins/HasTimelineItem'

export default {
  name: 'BookingTimelineItemEmail',

  mixins: [ HasTimelineItem ],

  data () {
    return {
      dialog: false
    }
  }
}
</script>
