<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Edit {{ bike.ref }}</span>

          <v-spacer></v-spacer>

          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <DeleteDialog
                :path="`bookings/${this.booking.id}/bikes/${this.bike.id}`"
                :delete-text="`Are you sure you want to delete ${bike.ref}?`"
                record-name="Bike"
                delete-permission="DELETE_BOOKING_BIKES"
                @deleted="closeDialog"
              >
                <template v-slot="{ on, attrs }">
                  <v-list-item
                    v-user-can:DELETE_BOOKING_BIKES
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-title>Delete Bike</v-list-item-title>
                  </v-list-item>
                </template>
              </DeleteDialog>
              <v-list-item @click="duplicateAsComplaintDialog = true">
                <v-list-item-title>Duplicate as Complaint</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-user-can:UPDATE_BIKES_COLLECTION_STATUS
                @click="updateStatusDialog = true; statusUpdateType = 'COLLECTION'; statusUpdateName = 'Collection'; updateStatus = bike.collection_status"
              >
                <v-list-item-title>Update Collection Status</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-user-can:UPDATE_BIKES_WORKSHOP_STATUS
                @click="updateStatusDialog = true; statusUpdateType = 'WORKSHOP'; statusUpdateName = 'Workshop'; updateStatus = bike.workshop_status"
              >
                <v-list-item-title>Update Workshop Status</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-user-can:UPDATE_BIKES_RETURN_STATUS
                @click="updateStatusDialog = true; statusUpdateType = 'RETURN'; statusUpdateName = 'Return'; updateStatus = bike.return_status"
              >
                <v-list-item-title>Update Return Status</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <h3 class="mb-4 primary--text">1. Service Info</h3>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.service_sku"
                  :rules="[v => !!v || 'Service is required']"
                  :items="services"
                  :item-text="service => { return `${service.name} (${service.sku}) - ${$currency(service.price)}` }"
                  item-value="sku"
                  label="Service"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-divider class="mb-6" />

            <h3 class="mb-4 primary--text">2. Bike Info</h3>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.manafacturer"
                  :rules="[v => !!v || 'Manufacturer is required']"
                  label="Manufacturer"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.model"
                  label="Model"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.colour"
                  label="Colour"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-if="services[0]"
                  v-model="form.type"
                  :rules="[v => !!v || 'Type is required']"
                  :items="services[0].available_types"
                  item-text="value"
                  item-value="key"
                  label="Type"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-divider class="mb-6" />

            <h3 class="mb-4 primary--text">3. Location Info</h3>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="form.location_prefix"
                  :items="availableLocationTypes"
                  label="Location Type"
                  item-text="label"
                  item-value="value"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.location"
                  label="Location"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-divider class="mb-6" />

            <h3 class="mb-4 primary--text">4. Customer Info</h3>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.additional_authorised_spend"
                  type="number"
                  step="0.01"
                  min="0"
                  prefix="£"
                  label="Authorised Spend"
                  hint="The amount of money the customer has authorised to spend"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <v-textarea
                  v-model="form.customer_notes"
                  rows="2"
                  label="Customer Notes"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-divider class="mb-6" />

            <h3 class="mb-4 primary--text">5. Job Info</h3>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="form.job_type"
                  :rules="[v => !!v || 'Job type is required']"
                  :items="jobTypes"
                  label="Job Type"
                  item-text="label"
                  item-value="value"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <AlgoliaSearch
                  v-model="form.asset"
                  :current="bike.asset || {}"
                  ref="algoliaSearch"
                  label="Asset"
                  index="assets"
                  item-text="number"
                  item-value="id"
                >
                  <template slot-scope="data">
                    <v-list-item-content>
                      <v-list-item-title class="search_highlight"><strong v-html="data.item._highlightResult.number.value" /></v-list-item-title>
                      <v-list-item-subtitle class="search_highlight" v-html="data.item._highlightResult.manafacturer.value" />
                      <v-list-item-subtitle class="search_highlight" v-html="data.item._highlightResult.assetable_name.value" />
                    </v-list-item-content>
                  </template>
                </AlgoliaSearch>
              </v-col>
            </v-row>
          </v-form>

          <FormErrors ref="formErrors" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" text @click="updateBike" :loading="loading">Update Bike <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <BikeDuplicateDialog
      :dialog.sync="duplicateAsComplaintDialog"
      v-bind="{ bike, booking }"
    />

    <v-dialog
      v-model="updateStatusDialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Update {{ statusUpdateName }} Status</span>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="updateStatus"
                :items="availableStatuses"
                :label="`${statusUpdateName} Status`"
                item-text="label"
                item-value="value"
                outlined
                required
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="updateStatusDialog = false">Close</v-btn>
          <v-btn color="primary" text @click="updateBikeStatus" :loading="loading">Update {{ statusUpdateName }} Status <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Bike updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the bike.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasBooking from '@/mixins/HasBooking'
import HasBike from '@/mixins/HasBike'
import HasForm from '@/mixins/HasForm'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'
import BikeDuplicateDialog from '@/components/bikes/BikeDuplicateDialog'
import AlgoliaSearch from '@/components/algolia-search/AlgoliaSearch'
import DeleteDialog from '@/components/DeleteDialog'

export default {
  name: 'EditBikeDialog',

  mixins: [ HasDialog, HasForm, HasBooking, HasBike ],

  components: {
    FormErrors,
    SnackBar,
    BikeDuplicateDialog,
    AlgoliaSearch,
    DeleteDialog
  },

  data () {
    return {
      form: {
        unknown_details: false,
        additional_authorised_spend: 0
      },
      loading: false,
      error: {},
      jobTypes: [],
      services: [],
      locationTypes: {},
      duplicateAsComplaintDialog: false,
      updateStatusDialog: false,
      statusUpdateType: null,
      statusUpdateName: null,
      updateStatus: null,
      collectionStates: [],
      workshopStates: [],
      returnStates: []
    }
  },

  mounted () {
    this.getServices()
    this.getLocationTypes()
    this.getJobCollectionStates()
    this.getJobWorkshopStates()
    this.getJobReturnStates()
    this.getJobTypes()
    this.form = {
      ...this.form,
      ...this.bike
    }
  },

  computed: {
    availableStatuses () {
      if (this.statusUpdateType === 'COLLECTION') {
        return this.collectionStates
      }
      if (this.statusUpdateType === 'WORKSHOP') {
        return this.workshopStates
      }
      if (this.statusUpdateType === 'RETURN') {
        return this.returnStates
      }
      return []
    },

    availableLocationTypes () {
      var types = []
      Object.keys(this.locationTypes).forEach(key => {
        types.push({
          label: this.locationTypes[key],
          value: key
        })
      })
      return types
    }
  },

  methods: {
    getLocationTypes () {
      this.$api.get({
        path: 'bike-location-types'
      })
        .then(data => {
          this.locationTypes = data
        })
    },

    getServices () {
      this.$api.get({
        path: 'services',
        params: {
          orderBy: 'price',
          ascending: 1,
          limit: 100,
          show_all: true
        }
      })
        .then(({ data }) => {
          this.services = data
        })
    },

    getJobCollectionStates () {
      this.$api.get({
        path: 'job-collection-states'
      })
        .then(states => {
          Object.keys(states).forEach((key) => {
            this.collectionStates.push({
              label: states[key],
              value: key
            })
          })
        })
    },

    getJobWorkshopStates () {
      this.$api.get({
        path: 'job-workshop-states'
      })
        .then(states => {
          Object.keys(states).forEach((key) => {
            this.workshopStates.push({
              label: states[key],
              value: key
            })
          })
        })
    },

    getJobTypes () {
      this.$api.get({
        path: 'job-types'
      })
        .then(types => {
          Object.keys(types).forEach((key) => {
            this.jobTypes.push({
              label: types[key],
              value: key
            })
          })
        })
    },

    getJobReturnStates () {
      this.$api.get({
        path: 'job-return-states'
      })
        .then(states => {
          Object.keys(states).forEach((key) => {
            this.returnStates.push({
              label: states[key],
              value: key
            })
          })
        })
    },

    updateBikeStatus () {
      this.$api.persist('put', {
        path: `bookings/${this.booking.id}/bikes/${this.bike.id}/status`,
        object: {
          type: this.statusUpdateType,
          status: this.updateStatus
        }
      })
        .then(() => {
          this.$refs.successBar.open()
          this.updateStatusDialog = false
          this.error = {}
          this.closeDialog()
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
          this.error = error
        })
    },

    updateBike () {
      this.loading = true
      this.$refs.formErrors.clear()

      if (this.$refs.form.validate()) {
        this.$api.persist('put', {
          path: `bookings/${this.booking.id}/bikes/${this.bike.id}`,
          object: {
            ...this.form,
            ...{
              asset_id: this.form.asset ? this.form.asset.id : null
            }
          }
        })
          .then(({ data: bike }) => {
            this.$emit('update:bike', bike)
            this.error = {}
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
            this.error = error
          })
          .finally(() => { this.loading = false })
      }
    }
  }
}
</script>
