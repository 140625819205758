<template>
  <div>
    <v-simple-table class="mt-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Bike</th>
            <th class="text-left">Status</th>
            <th class="text-left">Manifest</th>
            <th class="text-left">Revised Date</th>
            <th class="text-left">{{ markType }}</th>
            <th v-if="collection" class="text-left">{{ collection ? 'Arrived' : 'Dispatched' }} Workshop</th>
          </tr>
        </thead>
        <tbody>
          <BookingCRBikeRow
            v-for="bike in orderedBikes"
            v-bind="{ bike, booking, collection }"
            :key="bike.id"
            :error.sync="error"
            @update:booking="$refs.successBar.open()"
            @update:error="error => error.message ? $refs.errorBar.open() : null"
          />
        </tbody>
      </template>
    </v-simple-table>

    <SnackBar success ref="successBar">
      Bike updated successfully!
    </SnackBar>

    <SnackBar error ref="errorBar">
      {{ error.message }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import HasCollectionOrReturn from '@/mixins/HasCollectionOrReturn'
import SnackBar from '@/components/SnackBar'
import BookingCRBikeRow from '@/components/bookings/BookingCRBikeRow'
import orderBy from 'lodash.orderby'

export default {
  name: 'BookingCRTable',

  components: {
    SnackBar,
    BookingCRBikeRow
  },

  mixins: [ HasBooking, HasCollectionOrReturn ],

  data () {
    return {
      error: {}
    }
  },

  computed: {
    orderedBikes () {
      return orderBy(this.booking.bikes, 'ref', 'desc')
    }
  }
}
</script>
