<template>
  <div>
    <NoteBox :data="timelineItem.note">
      {{ timelineItem.note.content }}
      <template #footer>
        <small class="d-block text-right font-weight-medium mt-2">
          Noted By: {{ timelineItem.created_by.name }}
          <v-avatar size="20" color="primary">
            <img
              :src="timelineItem.created_by.profile_img_url"
              :alt="timelineItem.created_by.name">
          </v-avatar>
        </small>
      </template>
    </NoteBox>
  </div>
</template>
<script>
import HasTimelineItem from '@/mixins/HasTimelineItem'
import NoteBox from '@/components/notes/NoteBox'

export default {
  name: 'BookingTimelineItemNote',

  mixins: [ HasTimelineItem ],

  components: {
    NoteBox
  }
}
</script>
