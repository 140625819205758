<template>
  <div>
   <div v-user-can:READ_BOOKINGS_QC>
      <BookingBikeSelector
        v-model="bikeId"
        :booking="booking"
      />

      <v-row>
        <v-col>
          <v-alert
            outlined
            dense
            color="primary"
          >
            <h2 class="mb-2">{{ bike.service.name }}</h2>

            <NoteBox v-if="bike.customer_notes" :data="{ type: 'TYPE_CUSTOMER_NOTE' }">
              {{ bike.customer_notes }}
            </NoteBox>
            <p v-else>
              No customer notes to show
            </p>

            <!--
              <v-alert
                v-if="!bike.available_workshop_statuses.includes('STATUS_AWAITING_TRIAGE')"
                dense
                text
                outlined
                type="warning"
              >
                This bike still isn't ready for QC.
              </v-alert>
            -->

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_QC_STARTED')"
              :loading="loading"
              depressed
              small
              @click="updateBike('STATUS_QC_STARTED')"
            >
              Start QC
              <v-icon
                right
                dense
              >
                mdi-alert-circle-check
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_QC_FAILED')"
              :loading="loading"
              color="error"
              depressed
              small
              @click="updateBike('STATUS_QC_FAILED')"
            >
              QC Failed
              <v-icon
                right
                dense
              >
                mdi-alert-circle
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_QC_PASSED')"
              :loading="loading"
              color="success"
              class="ml-2"
              depressed
              small
              @click="updateBike('STATUS_QC_PASSED')"
            >
              QC Passed
              <v-icon
                right
                dense
              >
                mdi-alert-circle-check
              </v-icon>
            </v-btn>

          </v-alert>
        </v-col>
      </v-row>

      <v-alert
        v-if="newQc"
        dense
        outlined
        color="primary"
      >
        <v-row>
          <v-col :cols="6" class="py-0">
            <h2>Function</h2>
          </v-col>
          <v-col :cols="3" class="py-0">
            <p>Tech</p>
          </v-col>
          <v-col :cols="3" class="py-0">
            <p>QC</p>
          </v-col>
        </v-row>
      </v-alert>

      <template v-if="newQc">
        <BookingGCQuestionCard
          v-for="(question, key) in questions"
          v-model="checked"
          :key="key"
          :question="question"
        />
      </template>

    </div>
    <v-alert
      v-user-cant:READ_BOOKINGS_QC
      dense
      text
      outlined
      type="warning"
    >
      You don't have permission to view the quality control section.
    </v-alert>

    <SnackBar ref="errorBar" error>
      {{ error.message }}
    </SnackBar>
    <SnackBar ref="successBar" success>
      Bike updated successfully!
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import BookingBikeSelector from '@/components/bookings/BookingBikeSelector'
import HasBikeSelected from '@/mixins/HasBikeSelected'
import BookingGCQuestionCard from '@/components/bookings/BookingGCQuestionCard'
import SnackBar from '@/components/SnackBar'
import NoteBox from '@/components/notes/NoteBox'

export default {
  name: 'BookingQC',

  mixins: [ HasBooking, HasBikeSelected ],

  components: {
    BookingBikeSelector,
    BookingGCQuestionCard,
    SnackBar,
    NoteBox
  },

  data () {
    return {
      checked: [],
      newQc: false,
      loading: false,
      error: {},
      questions: [
        {
          title: 'Handlebar & stem bolt check',
          subtitle: 'and movement check',
          value: 'handlebar'
        },
        {
          title: 'Headset check',
          value: 'headset'
        },
        {
          title: 'ALL Brake bolt check (torqued)',
          subtitle: 'No movement in brake components',
          value: 'brake_bolt'
        },
        {
          title: 'Quick release lever secured',
          value: 'quick_release'
        },
        {
          title: 'Check front tyre is inflated',
          value: 'front_tyre'
        }
      ]
    }
  }
}
</script>
