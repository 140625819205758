<template>
  <div>
   <div>
      <BookingBikeSelector
        v-model="bikeId"
        :booking="booking"
      />

      <v-alert
        dense
        outlined
        color="primary"
        class="pt-4"
      >
        <v-select
          v-model="fileType"
          :items="fileTypes"
          dense
          outlined
        />
        <div class="pb-6">
          <FileUpload
            v-model="uploadFiles"
            multiple
            remove-on-upload-success
            @file:uploaded="file => handleFileUpload(file, fileType)"
          />          
        </div>
      </v-alert>

      <v-card outlined>
        <v-card-title>
          <span class="headline">Files</span>
        </v-card-title>
        <v-card-text>
          <FilesList
            :files="files"
            @file:deleted="getBikeFiles"
          />
          <div class="d-flex justify-space-between align-end w-full">
            <div>
              Showing {{ files.length }} / {{ meta.total }} files
            </div>
            <v-btn
              v-if="meta.current_page !== meta.last_page"
              class="mt-4"
              color="primary"
              small
              outlined
              @click="loadMoreFiles"
            >
              Load More
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <SnackBar ref="errorBar" error>
      {{ fileError.message }}
    </SnackBar>
    <SnackBar ref="fileSuccessBar" success>
      File uploaded and attached to bike successfully!
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import BookingBikeSelector from '@/components/bookings/BookingBikeSelector'
import HasBikeSelected from '@/mixins/HasBikeSelected'
import SnackBar from '@/components/SnackBar'
import FileUpload from '@/components/file-upload/FileUpload'
import FilesList from '@/components/files/FilesList'
import HasFileUpload from '@/mixins/HasFileUpload'

export default {
  name: 'BookingFiles',

  mixins: [ HasBooking, HasBikeSelected, HasFileUpload ],

  components: {
    BookingBikeSelector,
    SnackBar,
    FileUpload,
    FilesList
  },

  data () {
    return {
      loading: false,
      fileType: 'scan_files',
      uploadFiles: []
    }
  },

  methods: {
    bikeChanged () {
      this.getBikeFiles()
    }
  }
}
</script>
