<template>
  <tr>
    <td>
      {{ job.ref }}
      <strong v-if="job.asset">
        / {{ job.asset.number }}        
      </strong>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="grey"
            small
            dense
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ job.name }}</span>
      </v-tooltip>
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="triageServiceRecord" :job="job" />
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="cleanServiceRecord" :job="job" />
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="triageConfirmationServiceRecord" :job="job" />
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="serviceRecord" :job="job" />
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="qualityControlServiceRecord" :job="job" />
    </td>
    <td></td>
  </tr>
</template>

<script>
import HasServiceRecords from '@/mixins/HasServiceRecords'
import WorkshopDashboardServiceRecordCol from '@/components/dashboard/WorkshopDashboardServiceRecordCol'

export default {
  name: 'BookingServiceRecordRow',

  mixins: [ HasServiceRecords ],

  components: {
    WorkshopDashboardServiceRecordCol
  },

  props: {
    job: {
      type: Object,
      required: true
    }
  },

  data () {
    return {

    }
  }
}
</script>
