<template>
  <div>
    <strong>{{ timelineItem.subject }}</strong>
    <div class="caption">{{ timelineItem.communication.content }}</div>
    <div v-if="timelineItem.url" class="mt-2">
      <audio controls>
        <source :src="timelineItem.url" type="audio/mpeg">
      </audio>
    </div>
  </div>
</template>
<script>
import HasTimelineItem from '@/mixins/HasTimelineItem'

export default {
  name: 'BookingTimelineItemPhone',

  mixins: [ HasTimelineItem ],

  data () {
    return {
      dialog: false
    }
  }
}
</script>
