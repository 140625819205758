<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title :class="{'primary': !form.safety_critical, 'red lighten-1': form.safety_critical, 'white--text': true }">
          <span class="headline">New {{ form.safety_critical ? 'Safety Critical Sundry' : 'Sundry' }}</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <h3 class="mb-4 primary--text">1. Bicycle</h3>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.booking_bike_id"
                  :rules="[v => !!v || 'Bike is required']"
                  :items="booking.bikes"
                  :item-text="bike => { return `${bike.manafacturer || ''} ${bike.model || ''} (${bike.ref}) ${bike.asset ? '- ' + bike.asset.number : ''} ${bike.job_type === 'JOB_TYPE_C&R_COMPLAINT' ? '[Complaint]' : ''}` }"
                  :disabled="booking.bikes.length === 1 || (defaultBike ? true : false)"
                  item-value="id"
                  label="Bike"
                  hint="The bike that this sundry is related to."
                  outlined
                  required
                  dense
                >
                  <template v-slot:item="{ item: bike }">
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>{{ bike.name }} {{ bike.asset ? '- ' + bike.asset.number : null }}</strong>
                        <v-icon v-if="bike.job_type === 'JOB_TYPE_C&R_COMPLAINT'" color="red darken-2" small right>mdi-exclamation-thick</v-icon>
                        <small class="red--text darken-2">{{ bike.job_type === 'JOB_TYPE_C&R_COMPLAINT' ? '[Complaint]' : '' }}</small>
                      </v-list-item-title>
                      <v-list-item-subtitle class="text--primary">{{ bike.workshop_status_label }}</v-list-item-subtitle>
                      <v-list-item-subtitle>TBO: {{ $momentDate(bike.planned_service_date) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>{{ bike.ref }}</v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                  <template v-slot:selection="{ item: bike }">
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>{{ bike.name }}</strong>
                        <v-icon v-if="bike.job_type === 'JOB_TYPE_C&R_COMPLAINT'" color="red darken-2" small right>mdi-exclamation-thick</v-icon>
                        <small class="red--text darken-2">{{ bike.job_type === 'JOB_TYPE_C&R_COMPLAINT' ? '[Complaint]' : '' }}</small>
                      </v-list-item-title>
                      <v-list-item-subtitle class="text--primary">{{ bike.ref }} {{ bike.asset ? '- ' + bike.asset.number : null }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="text--primary">{{ bike.workshop_status_label }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-8 mt-2" />

            <h3 class="mb-4 primary--text">2. Sundry Type</h3>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-alert
                  dense
                  text
                  outlined
                >
                  <p class="mb-0 pb-0"><strong>Is this sundry labour?</strong></p>
                  <p class="mt-0 pt-0">Please tick the box if this sundry <b><u>IS</u></b> only for labour <i>(e.g. fitting a part)</i></p>
                  <v-checkbox
                    v-model="form.is_labour"
                    :disabled="(!form.is_labour && defaultIsPart) || (form.is_labour && defaultIsLabour)"
                    :label="form.is_labour ? 'This sundry is labour' : 'This sundry is NOT labour (e.g. this sundry is a part)'"
                    hide-details
                  />
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pt-0">
                <v-alert
                  dense
                  text
                  color="red lighten-1"
                  outlined
                >
                  <p class="mb-0 pb-0"><strong>Is this sundry required to complete the service?</strong></p>
                  <p class="mt-0 pt-0">Please tick the box if this sundry is related to {{ form.is_labour ? 'work' : 'a part' }} that <b><u>IS</u></b> safety critical.</p>
                  <v-checkbox
                    v-model="form.safety_critical"
                    :label="form.safety_critical ? `This ${form.is_labour ? 'work' : 'part'} IS safety critical` : `This ${form.is_labour ? 'work' : 'part'} is NOT safety critical`"
                    hide-details
                  />
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col v-if="availableBikeSundries.length > 0" cols="12">
                <v-select
                  v-model="form.associated_sundry"
                  :disabled="((defaultAssociatedSundry ? defaultAssociatedSundry.id : false) ? true : false) && !form.is_labour"
                  :items="availableBikeSundries"
                  :label="`Associated ${this.form.is_labour ? 'Part' : 'Labour / Fitting'}`"
                  :item-text="(sundry) => { return sundry.name }"
                  :hint="`If you wish to associate this sundry with ${form.is_labour ? 'a part' : 'labour'}, please select it here.`"
                  item-value="id"
                  clearable
                  return-object
                  persistent-hint
                  outlined
                  required
                  dense
                >
                  <template v-slot:item="{ item: sundry }">
                    <v-list-item-content>
                      <v-list-item-title><strong>{{ sundry.name }}</strong></v-list-item-title>
                      <v-list-item-subtitle v-text="sundry.description" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>
                        <span class="green--text">{{ sundry.qty > 1 ? `(x ${sundry.qty}) ` : null }}</span>
                        <strong>{{ $currency(sundry.price) }}</strong>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                  <template v-slot:selection="{ item: sundry }">
                    <v-list-item-content>
                      <v-list-item-title><strong>{{ sundry.name }}</strong></v-list-item-title>
                      <v-list-item-subtitle v-text="sundry.description" />
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-divider class="mb-8 mt-2" />

            <h3 class="mb-4 primary--text">3. Sundry Information</h3>
            <v-row>
              <v-col
                cols="12"
              >
                <SundryAlgoliaSearch
                  v-if="!showSundryBreakdown"
                  v-model="form.item_sku"
                  :labour="form.is_labour"
                  :disabled="!selectedBike.id"
                  :bike="selectedBike"
                  autofocus
                  @update="handleItemChange"
                />
                <v-btn v-if="!showSundryBreakdown" x-small depressed color="primary" @click="showSundryBreakdown = true">Manual Sundry Entry</v-btn>
                <v-btn v-else x-small depressed color="primary" @click="resetSundryBreakdown">Reset</v-btn>
              </v-col>
            </v-row>

            <v-divider v-if="showSundryBreakdown" class="mb-10 mt-4"/>

            <v-row v-if="showSundryBreakdown">
              <v-col
                cols="12"
                sm="8"
              >
                <v-text-field
                  v-model="form.name"
                  :rules="[v => !!v || 'Sundry name is required']"
                  label="Sundry Name"
                  hint="The line item name for this sundry - this is what the customer will see."
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                v-if="!form.is_labour"
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.manufacturer"
                  label="Manufacturer"
                  hint="The manufacturer of this part."
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row v-if="showSundryBreakdown">
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.qty"
                  :rules="[v => !!v || 'Quantity is required']"
                  type="number"
                  step="1"
                  min="1"
                  label="Quantity"
                  hint="The number of parts taken for this sundry"
                  outlined
                  required
                  dense
                />
              </v-col>

              <v-col
                v-if="form.item_sku"
                cols="4"
              >
                <v-tooltip
                  bottom
                  right
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :outlined="!autoCalc"
                      color="primary"
                      style="height: 40px;"
                      block
                      depressed
                      @click="autoCalc = !autoCalc"
                    >
                      <small>Auto-Calc Price <strong>{{ autoCalc ? 'ON' : 'OFF' }}</strong></small>
                    </v-btn>
                  </template>
                  <span>When enabled, the total price is automatically calculated based on the base price of the sundry, multiplied by the quantity.</span>
                </v-tooltip>
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.price"
                  :label="`Total Price ${form.qty > 1 ? `(For ${form.qty})` : ''}`"
                  type="number"
                  step="0.01"
                  prefix="£"
                  hint="The total price of this sundry - this is what the customer will see."
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-row v-if="showSundryBreakdown">
              <v-col cols="12">
                <v-select
                  v-model="form.reason"
                  :rules="[v => !!v || 'Reason is required']"
                  :items="form.is_labour ? labourReasons : partReasons"
                  :label="form.is_labour ? 'Reason For Work' : 'Reason For Replacement'"
                  :disabled="warranty ? true : false"
                  item-text="label"
                  return-object
                  outlined
                  required
                  dense
                  @change="handleReasonChange"
                />
              </v-col>
            </v-row>

            <v-row v-if="warranty">
              <v-col cols="12">
                <v-alert
                  dense
                  text
                  color="orange lighten-1"
                  outlined
                >
                  <p>
                    <strong>Warranty created for this sundry</strong>
                  </p>

                  <v-divider class="mb-3 mt-2" />

                  <p>Price has been reduced to £0 & cutomer will be informed this part will be replaced under warranty.</p>
                </v-alert>
              </v-col>
            </v-row>

            <v-row v-if="form.reason && showSundryBreakdown">
              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  :rules="[v => !!v || 'Reason is required']"
                  rows="2"
                  :label="form.is_labour ? 'Reason For Work (text visible to customer)' : 'Reason For Replacement (text visible to customer)'"
                  outlined
                  required
                  dense
                  @change="descriptionChange"
                />
              </v-col>
            </v-row>

            <v-row v-if="!form.is_labour && showSundryBreakdown && form.item_sku">
              <v-col cols="12">
                <v-alert
                  dense
                  text
                  :color="`${inStock(form.item_sku) ? (qtyHigherThanStock ? 'orange' : 'green') : 'red'} lighten-1`"
                  outlined
                >
                  <p class="mb-0 pb-0">
                    <strong v-if="inStock(form.item_sku) && !qtyHigherThanStock">
                      This item is in stock!
                    </strong>
                    <strong v-else-if="qtyHigherThanStock">
                      We don't have enough stock to fulfill the quantity you need.
                    </strong>
                    <strong v-else>
                      This item is NOT in stock
                    </strong>
                  </p>

                  <v-divider class="mb-3 mt-2" />

                  <v-row v-if="!qtyHigherThanStock && inStock(form.item_sku)">
                    <v-col v-if="(form.item_sku.hubs[$hubs.currentHub.id] ? form.item_sku.hubs[$hubs.currentHub.id].stock_locations.length : []) === 1" cols="12">
                      <p>Please pick the item from: <strong><u>{{ form.location.name }}</u>. <small>(There {{ form.location.allocation.stock_available === 1 ? 'is' : 'are' }} {{ form.location.allocation.stock_available }} available)</small></strong></p>
                    </v-col>
                    <v-col v-else cols="12">
                      <p>Select the location to pick this item from:</p>
                      <v-select
                        v-model="form.location"
                        :items="form.item_sku.hubs[$hubs.currentHub.id].stock_locations"
                        :rules="[v => !!v || 'Location is required']"
                        label="Location"
                        return-object
                        item-value="id"
                        required
                        outlined
                        dense
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.name }} <span v-if="data.item.allocation">({{ data.item.allocation.stock }} in stock)</span></v-list-item-title>
                            <v-list-item-subtitle>{{ data.item.short_name }} - {{ data.item.identifier }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <template v-slot:selection="data">
                          <span>
                            {{ data.item.name }} <span v-if="data.item.allocation">({{ data.item.allocation.stock }} in stock)</span>
                          </span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <p>This product is available from the following suppliers:</p>
                      <div v-for="supplier in form.item_sku.suppliers" :key="supplier.id">
                        <p><strong>{{ supplier.name }}</strong> <small>({{ supplier.purchase_data.estimated_available_date ? 'Available from ' + $momentDate(supplier.purchase_data.estimated_available_date) : 'Availability unkown' }})</small></p>
                      </div>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>

            <v-row v-else-if="!form.is_labour && showSundryBreakdown">
              <v-col cols="12">
                <v-alert
                  dense
                  text
                  color="red"
                  outlined
                >
                  <p class="mb-0 pb-0">
                    <strong>Do we have this item is stock?</strong>
                  </p>
                  <p>Please click the box below if we have this item in stock and ready to use.</p>
                  <v-divider class="mb-3 mt-2" />
                  <v-checkbox
                    v-model="form.in_stock"
                    :label="form.in_stock ? 'Item IS in stock at havebike' : 'Item IS NOT in stock at havebike'"
                  />
                </v-alert>
              </v-col>
              <v-col v-if="!form.in_stock" cols="12">
                <v-alert
                  dense
                  text
                  color="orange"
                  outlined
                >
                  <p class="mb-0 pb-0">
                    <strong>This is a special order item.</strong>
                  </p>
                  <p>Please fill in the details below to help the purchaser!</p>

                  <v-divider class="mb-5 mt-2" />

                  <v-row>
                    <v-col sm="6" cols="12">
                      <v-text-field
                        v-model="form.special_order_link"
                        label="Supplier / Website / Link"
                        hint="The number of parts taken for this sundry"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col sm="6" cols="12">
                      <v-textarea
                        v-model="form.special_order_notes"
                        label="Additional Purchaser Notes"
                        hint="The number of parts taken for this sundry"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>

            <template v-if="showSundryBreakdown">
              <v-divider class="mb-6 mt-2" />

              <h3 class="mb-4 primary--text">4. Billing Information</h3>
              <p>If this sundry is being added before the customer has approved the spend, please review the information below.</p>      
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <v-checkbox
                    v-model="form.estimate"
                    :label="form.estimate ? 'Added BEFORE customer approved spend' : 'Added AFTER customer approved spend'"
                  />
                </v-col>
              </v-row>              
            </template>

            <FormErrors ref="formErrors" />

          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn :color="form.safety_critical ? 'red' : 'primary'" :loading="loading" text @click="createSundry(false)">Add {{ form.is_labour ? 'Labour' : 'Part' }} Sundry <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
          <v-btn :color="form.safety_critical ? 'red' : 'primary'" :loading="loading" text @click="createSundry(true)">Add Sundry & Add Another <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addAssociatedSundryDialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="white--text primary">
          <span class="headline">Add Associated {{ form.is_labour ? 'Part' : 'Labour' }}?</span>
        </v-card-title>

        <v-card-text class="pt-4">
          <p>You have just added a <strong>{{ form.is_labour ? 'Labour' : 'Part' }}</strong>, if there is {{ form.is_labour ? 'a part' : 'labour' }} associated please click below.</p>

          <v-btn
            color="primary"
            depressed
            block
            @click="addAssociatedSundry"
          >
            Add Associated - <strong class="ml-1">{{ form.is_labour ? 'Part' : 'Labour' }}</strong>
          </v-btn>

          <v-btn
            class="mt-6"
            text
            depressed
            block
            small
            @click="noAssociatedSundry"
          >
            There is nothing else to add
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <WarrantySundryDialog
      v-if="selectedBike && form.item_sku"
      :dialog.sync="warrantyDialog"
      :bike="selectedBike"
      :sundry="form.item_sku"
      @canceled="form.reason = null"
      @warranty:created="handleWarrantyCreated"
    />

    <SnackBar success ref="successBar">
      Sundry added successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue adding the sundry.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'
import SundryAlgoliaSearch from '@/components/sundries/SundryAlgoliaSearch'
import WarrantySundryDialog from '@/components/sundries/WarrantySundryDialog'
import HasAssociatedSundries from '@/mixins/HasAssociatedSundries'

export default {
  name: 'AddSundryDialog',

  mixins: [ HasBooking, HasDialog, HasForm, HasAssociatedSundries ],

  components: {
    FormErrors,
    SnackBar,
    SundryAlgoliaSearch,
    WarrantySundryDialog
  },

  props: {
    defaultBike: {
      type: Object,
      required: false,
      default: () => { return null }
    },

    defaultAssociatedSundry: {
      type: Object,
      required: false,
      default: () => { return null }
    },

    defaultIsPart: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    defaultIsLabour: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  watch: {
    'form.booking_bike_id' () {
      if (!this.addAssociatedSundryDialog) {
        this.form.associated_sundry = {}
        this.getSundries()
      }
    },

    'form.is_labour' () {
      if (!this.addAssociatedSundryDialog) {
        this.form.associated_sundry = {}        
      }
    },

    'form.reason' () {
      if (this.form.reason && !this.descriptionHasBeenEdited) {
        this.$set(this.form, 'description', this.form.reason.text)
      }
    },

    'form.qty' () {
      this.updateStockQtyState()
      if (this.autoCalc) {
        this.calculateQtyPrice()
      }
    },

    autoCalc (newVal) {
      if (newVal) {
        this.calculateQtyPrice()
      }
    },

    dialog (dialog) {
      this.$set(this.form, 'estimate', true)
      this.$set(this.form, 'associated_sundry', {})

      if (this.defaultBike?.id) {
        this.$set(this.form, 'booking_bike_id', this.defaultBike.id)
      }

      if (dialog) {
        this.getSundryLabourReasons()
        this.getSundryPartReasons()
        this.resetSundryBreakdown()
        this.getSundries()

        this.warranty = null

        if (this.defaultIsLabour) {
          this.$set(this.form, 'is_labour', true)
        }
      }
      if (this.oneBike && dialog) {
        this.form.booking_bike_id = this.booking.bikes[0].id
      }
    }
  },

  data () {
    return {
      loading: false,
      addAssociatedSundryDialog: false,
      addAnother: false,
      form: {
        in_stock: false,
        estimate: true,
        item_sku: null,
        is_labour: false,
        associated_sundry: {},
        qty: 1
      },
      autoCalc: true,
      descriptionHasBeenEdited: false,
      showSundryBreakdown: false,
      expectedDeliveryDateDialog: false,
      partReasons: [],
      labourReasons: [],
      warrantyDialog: false,
      warranty: null
    }
  },

  computed: {
    oneBike () {
      return this.booking.bikes.length === 1
    },

    selectedBike () {
      var selectedBike = {}
      if (this.oneBike) {
        selectedBike = this.booking.bikes[0]
      } else {
        this.booking.bikes.forEach(bike => {
          if (bike.id === this.form.booking_bike_id) {
            selectedBike = bike
          }
        })
      }
      return selectedBike
    },

    qtyHigherThanStock () {
      return this.form.location ? this.form.qty > this.form.location?.allocation?.stock : false
    }
  },

  methods: {
    handleWarrantyCreated (warranty) {
      this.$set(this.form, 'price', 0.00)
      this.warranty = warranty
    },

    inStock (item) {
      return item.hubs[this.$hubs.currentHub.id] ? item.hubs[this.$hubs.currentHub.id].in_stock : false
    },

    handleItemChange (item) {
      var hubItem = item.hubs ? item.hubs[this.$hubs.currentHub.id] : {}
      if (item) {
        this.$set(this.form, 'supplier_id', null)
        this.$set(this.form, 'estimated_delivery_date', null)

        if (item.name) {
          this.$set(this.form, 'name', item.name)
        }
        this.$set(this.form, 'price', (this.selectedBike.service.full_service && this.form.is_labour && item.price_with_service) ? item.price_with_service : item.price || item.rrp)
        this.$set(this.form, 'supplier_id', { id: item.supplier_id, name: item.supplier_name })
        this.$set(this.form, 'manufacturer', item.manufacturer?.name)
        this.$set(this.form, 'in_stock', hubItem ? hubItem.in_stock : false)
        this.$set(this.form, 'qty', 1)
        if (hubItem?.in_stock) {
          this.$set(this.form, 'location', hubItem.stock_locations[0])
        }

        if (!hubItem?.in_stock && typeof item.suppliers !== 'undefined' && item.suppliers.length > 0 && item.suppliers[0]?.purchase_data?.estimated_delivery_date) {
          this.$set(this.form, 'estimated_delivery_date', this.$moment(item.suppliers[0]?.purchase_data?.estimated_delivery_date).format('YYYY-MM-DD'))
        }
        if (this.qtyHigherThanStock) {
          this.$set(this.form, 'in_stock', false)
        }
        this.showSundryBreakdown = true
      }
    },

    updateStockQtyState () {
      if (this.form.in_stock) {
        this.$set(this.form, 'in_stock', !this.qtyHigherThanStock) 
      }
    },

    descriptionChange () {
      this.descriptionHasBeenEdited = true
    },

    resetSundryBreakdown () {
      this.$set(this.form, 'supplier_id', null)
      this.$set(this.form, 'estimated_delivery_date', null)
      this.$set(this.form, 'name', null)
      this.$set(this.form, 'price', null)
      this.$set(this.form, 'reason', null)
      this.$set(this.form, 'location', null)
      this.$set(this.form, 'manufacturer', null)
      this.$set(this.form, 'description', null)
      this.$set(this.form, 'item_sku', null)
      this.$set(this.form, 'in_stock', false)
      this.$set(this.form, 'qty', 1)
      this.descriptionHasBeenEdited = false
      this.showSundryBreakdown = false
    },

    calculateQtyPrice () {
      if (this.form.item_sku) {
        this.$set(this.form, 'price', (this.form.qty * (this.form?.item_sku?.price || this.form?.item_sku?.rrp || 0)).toFixed(2))
      }
    },

    getSundryLabourReasons () {
      this.$api.get({
        path: 'sundry-labour-reasons'
      })
        .then(reasons => {
          Object.keys(reasons).forEach(reasonKey => {
            this.labourReasons.push({
              label: reasons[reasonKey].label,
              text: reasons[reasonKey].text,
              value: reasonKey
            })
          })
        })
    },

    getSundryPartReasons () {
      this.$api.get({
        path: 'sundry-replacement-reasons'
      })
        .then(reasons => {
          Object.keys(reasons).forEach(reasonKey => {
            this.partReasons.push({
              label: reasons[reasonKey].label,
              text: reasons[reasonKey].text,
              value: reasonKey
            })
          })
        })
    },

    createSundry (addAnother = false) {
      if (this.$refs.form.validate()) {
        this.$refs.formErrors.clear()
        this.loading = true
        this.$api.persist('post', {
          path: `bookings/${this.booking.id}/sundries`,
          object: {
            ...this.form,
            ...{
              warranty_id: this.warranty?.id,
              associated_sundry_id: this.form.associated_sundry?.id,
              stock_product_id: this.form.item_sku?.id,
              item_sku: (this.form.item_sku ? this.form.item_sku.id : null) ? this.form.item_sku.sku : this.form.item_sku,
              in_stock: this.form.is_labour ? true : this.form.in_stock,
              supplier_id: this.form.supplier_id?.id,
              location_id: this.form.location?.allocation?.id,
              location_name: this.form.location ? `${this.form.location.name} (${this.form.location.short_name})` : null,
              reason_key: this.form.reason?.value,
              estimated_delivery_date: (this.form.is_labour || this.form.in_stock) ? null : this.form.estimated_delivery_date,
              manufacturer: this.form.is_labour ? null : this.form.manufacturer
            }
          }
        })
          .then(({ data: sundry }) => {
            if (!this.form.associated_sundry.id) {
              this.addAssociatedSundryDialog = true
            } else {
              this.noAssociatedSundry()
            }
            this.resetSundryBreakdown()
            this.$set(this.form, 'in_stock', false)
            this.$set(this.form, 'safety_critical', false)
            this.$refs.form.resetValidation()
            this.bikeSundries.push(sundry)
            this.$refs.successBar.open()
            this.form.associated_sundry = sundry
            this.addAnother = addAnother

            if (!sundry.is_labour) {
              var reason
              this.labourReasons.forEach(labourReason => {
                if (labourReason.value === 'REASON_FITTING_PART') {
                  reason = labourReason
                }
              })

              if (reason) {
                this.form.reason = reason
              }
            }
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    },

    noAssociatedSundry () {
      this.addAssociatedSundryDialog = false
      if (this.addAnother) {
        this.resetSundryBreakdown()
        this.$set(this.form, 'is_labour', false)
        this.$set(this.form, 'in_stock', false)
        this.$set(this.form, 'safety_critical', false)
        this.$refs.form.resetValidation()
        this.addAnother = false
      } else {
        this.closeDialog()        
      }
    },

    addAssociatedSundry () {
      this.$set(this.form, 'is_labour', !this.form.is_labour)
      this.$nextTick(() => {
        this.addAssociatedSundryDialog = false
      })
    },

    handleReasonChange (reason) {
      // Start Warranty Flow
      if (reason.value === 'REASON_WARRANTY') {
        this.warrantyDialog = true
      }
    }
  }
}
</script>
<style>
  .search_item_meta {
    display: flex;
    flex-direction: column;
  }
</style>
