<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="1400px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Complete Triage</span>
        </v-card-title>
        <v-card-text class="pt-8">

          <v-alert
            v-if="sundries.length === 0 && !openedPaymentDialog"
            class="mt-2"
            dense
            text
            outlined
            type="warning"
          >
            There are no extra sundries on this bike. If that's not correct, please add them before approving triage.
          </v-alert>

          <v-alert
            v-if="sundries.length === 0 && openedPaymentDialog"
            class="mt-2"
            dense
            text
            outlined
            type="success"
          >
            Payment has been taken successfully, now please confirm the triage!
          </v-alert>

          <v-simple-table
            v-if="sundries.length > 0"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Part / Labour</th>
                  <th class="text-left">Associated Items</th>
                  <th class="text-left">Stock</th>
                  <th class="text-left">Approved</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="sundry in sundries"
                  :key="sundry.id"
                  :class="{'blue lighten-4': sundry.is_labour}"
                >
                  <td class="py-1">
                    <PartsToOrderPartColumn :sundry="sundry" />
                  </td>
                  <td>
                    <v-simple-table>
                      <thead>
                        <tr
                          :class="{'blue lighten-4': sundry.is_labour}"
                        >
                          <td><span class="font-weight-bold">Part:</span> {{ sundry.name }} <small class="red--text font-weight-bold">{{ sundry.qty > 1 ? `(x ${sundry.qty})` : null }}</small></td>
                          <td :class="{'text-left': true, 'green--text darken-2': !sundry.estimate}" style="width: 90px;">
                            <span v-if="! sundry.warranty_id">{{ $currency(sundry.price) }}</span>
                            <s v-else>{{ $currency(sundry.price) }}</s>
                            <div v-if="sundry.warranty_id"><strong class="red--text"><small>warranty</small></strong></div>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="associatedSundry in sundry.associated_sundries"
                          :key="associatedSundry.id"
                          :class="{'blue lighten-4': associatedSundry.is_labour}"
                        >
                          <td><span class="font-weight-bold">Laour:</span> {{ associatedSundry.name }} <small class="red--text font-weight-bold">{{ associatedSundry.qty > 1 ? `(x ${associatedSundry.qty})` : null }}</small></td>
                          <td :class="{'text-left': true, 'green--text darken-2': !associatedSundry.estimate}" style="width: 90px;">{{ $currency(associatedSundry.price) }}</td>
                        </tr>
                        <tr>
                          <td class="font-weight-black">Sub-Total</td>
                          <td :class="{'font-weight-black': true, 'green--text darken-2': !allAssociatedAndSundryApproved(sundry)}">{{ $currency(sundrySubtotal(sundry)) }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </td>
                  <td>
                    <v-icon
                      v-if="sundry.in_stock"
                      small
                      dense
                      color="green"
                    >
                      mdi-check-circle-outline
                    </v-icon>
                    <v-icon
                      v-else
                      small
                      dense
                      color="red"
                    >
                      mdi-close-circle-outline
                    </v-icon>
                  </td>
                  <td>
                    <v-btn-toggle>
                      <v-btn
                        :color="allAssociatedAndSundryApproved(sundry) ? 'primary' : 'error'"
                        dense
                        x-small
                        depressed
                        @click="approveSundry(sundry)"
                      >
                        {{ allAssociatedAndSundryApproved(sundry) ? 'Approve' : 'Remove' }}
                      </v-btn>
                      <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :color="allAssociatedAndSundryApproved(sundry) ? 'primary' : 'error'"
                            dense
                            x-small
                            depressed
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small color="white">mdi-menu-down-outline</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-subheader>Approve Sub Items</v-subheader>
                          <v-list-item :class="{'green lighten-2': !sundry.estimate}" @click="sundry.estimate = !sundry.estimate">
                            <v-list-item-subtitle class="text--primary">
                              {{ sundry.name }}
                            </v-list-item-subtitle>
                          </v-list-item>
                          <v-list-item
                            v-for="associatedSundry in sundry.associated_sundries"
                            :key="associatedSundry.id"
                            :class="{'green lighten-2': !associatedSundry.estimate}"
                            @click="associatedSundry.estimate = !associatedSundry.estimate"
                          >
                            <v-list-item-subtitle class="text--primary">
                              {{ associatedSundry.name }}
                            </v-list-item-subtitle>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-btn-toggle>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-divider class="mb-6" />

          <v-simple-table
            v-if="sundries.length > 0"
            class="mb-4"
          >
            <template v-slot:default>
              <tbody>
                <tr>
                  <td><strong>Estimated Total</strong></td>
                  <td style="text-align: right;">{{ $currency(estimateTotal) }}</td>
                </tr>
                <tr>
                  <td><strong>New Total</strong></td>
                  <td style="text-align: right;"><strong>{{ $currency(newTotal) }}</strong></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row class="mt-4">
            <v-col>
              <v-btn
                :loading="loading"
                :disabled="!bike.available_workshop_statuses.includes('STATUS_TRIAGE_UNABLE_TO_CONTACT')"
                color="error"
                block
                depressed
                @click="$emit('update:bike', { ...bike, ...{ workshop_status: 'STATUS_TRIAGE_UNABLE_TO_CONTACT' }}); closeDialog()"
              >
                Unable to Contact
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                :loading="loading"
                :disabled="!bike.available_workshop_statuses.includes('STATUS_CUSTOMER_CONSIDERING')"
                color="orange"
                :dark="bike.available_workshop_statuses.includes('STATUS_CUSTOMER_CONSIDERING')"
                block
                depressed
                @click="$emit('update:bike', { ...bike, ...{ workshop_status: 'STATUS_CUSTOMER_CONSIDERING' }}); closeDialog()"
              >
                Customer Considering
              </v-btn>
            </v-col>
            <v-col>
              <div v-if="sundries.length > 0" class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :loading="loading"
                      color="green white--text"
                      block
                      depressed
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ `Customer Agreed ${$currency(newTotal)} Extra` }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="approveSundries(false)">
                      <v-list-item-title>Approve Additional Work Only</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="approveSundries(true)">
                      <v-list-item-title>
                        Approve Additional Work & Take Payment
                        <v-icon small>mdi-credit-card-plus-outline</v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-btn
                v-else
                :loading="loading"
                color="green white--text"
                block
                depressed
                @click="approveSundries(false)"
              >
                Complete Triage
              </v-btn>
            </v-col>
          </v-row>

          <FormErrors ref="formErrors" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddPaymentChooseTypeDialog
      :dialog.sync="paymentDialog"
      :booking="booking"
    />
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasBooking from '@/mixins/HasBooking'
import HasBike from '@/mixins/HasBike'
import FormErrors from '@/components/FormErrors'
import AddPaymentChooseTypeDialog from '@/components/payments/AddPaymentChooseTypeDialog'
import PartsToOrderPartColumn from '@/components/dashboard/PartsToOrderPartColumn'

export default {
  name: 'CompleteTriageDialog',

  mixins: [ HasDialog, HasBooking, HasBike ],

  components: {
    FormErrors,
    AddPaymentChooseTypeDialog,
    PartsToOrderPartColumn
  },

  data () {
    return {
      error: {},
      sundries: [],
      loading: false,
      openedPaymentDialog: false,
      paymentDialog: false
    }
  },

  watch: {
    dialog: {
      immediate: true,
      handler () {
        this.openedPaymentDialog = false
        this.getSundries()
      }
    },
    paymentDialog: {
      handler () {
        this.getSundries()
      }
    }
  },

  computed: {
    estimateTotal () {
      var total = 0
      this.sundries.forEach(sundry => {
        total = total + sundry.price

        sundry.associated_sundries.forEach(associatedSundry => {
          total = total + associatedSundry.price
        })
      })
      return total
    },

    newTotal () {
      var total = 0
      this.sundries.forEach(sundry => {
        if (!sundry.estimate) {
          total = total + sundry.price          
        }

        sundry.associated_sundries.forEach(associatedSundry => {
          if (!associatedSundry.estimate) {
            total = total + associatedSundry.price
          }
        })
      })
      return total
    }
  },

  methods: {
    allAssociatedAndSundryApproved (sundry) {
      var approved = true

      sundry.associated_sundries.forEach(associatedSundry => {
        if (!associatedSundry.estimate && !sundry.estimate) {
          approved = false
        }
      })

      if (!sundry.estimate && !approved) {
        approved = false
      }

      return approved
    },

    approveSundry (sundry) {
      sundry.estimate = !sundry.estimate

      sundry.associated_sundries.forEach(associatedSundry => {
        associatedSundry.estimate = !associatedSundry.estimate
      })
    },

    sundrySubtotal (sundry) {
      var subTotal = sundry.price

      sundry.associated_sundries.forEach(associatedSundry => {
        subTotal = subTotal + associatedSundry.price
      })

      return subTotal
    },

    getSundries () {
      this.loading = true
      this.$api.get({
        path: `bookings/${this.booking.id}/sundries`,
        params: {
          with: ['product', 'supplier', 'bookingBike.booking', 'associatedSundries'],
          onlyEstimates: true,
          noAssociated: true,
          noDeclined: true,
          bikeId: this.bike.id,
          orderBy: 'created_at',
          ascending: false,
          limit: 10 * 10 * 10
        }
      })
      .then(({ data: sundries}) => {
        this.sundries = sundries
      })
      .finally(() => this.loading = false)
    },

    approveSundries (payment = false) {
      if (this.sundries.length > 0) {
        this.$refs.formErrors.clear()
        this.error = null
        this.loading = true
        var requests = []
        this.sundries.forEach(sundry => {
          requests.push({
            path: `bookings/${this.booking.id}/sundries/${sundry.id}`,
            object: {
                estimate: sundry.estimate ? true : false,
                declined: sundry.estimate ? true : false
            }
          })

          sundry.associated_sundries.forEach(associatedSundry => {
            requests.push({
              path: `bookings/${this.booking.id}/sundries/${associatedSundry.id}`,
              object: {
                  estimate: associatedSundry.estimate ? true : false,
                  declined: associatedSundry.estimate ? true : false
              }
            })
          })
        })
        this.$api.persistMultiple('put', requests)
          .then(() => {
            if (payment) {
              this.paymentDialog = true
              this.openedPaymentDialog = true
            } else {
              this.$emit('update:bike', { ...this.bike, ...{ workshop_status: 'STATUS_TRIAGE_CONFIRMED' }})
              this.closeDialog()
            }
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
          })
          .finally(() => this.loading = false)
      } else {
        this.$emit('update:bike', { ...this.bike, ...{ workshop_status: 'STATUS_TRIAGE_CONFIRMED' }})
        this.closeDialog()
      }
    }
  }
}
</script>
