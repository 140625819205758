<template>
  <div>
    <h3 class="mb-3">Payment Status: {{ booking.payment_status_label }}</h3>
    <v-simple-table
      dense
    >
      <template v-slot:default>
        <tbody>
          <tr>
            <td style="width: 150px;"><strong>Sub-Total</strong></td>
            <td>{{ $currency(booking.subtotal) }}</td>
          </tr>
          <tr>
            <td><strong>Sundries</strong></td>
            <td>{{ $currency(sundries) }}</td>
          </tr>
          <tr>
            <td><strong>Discount</strong></td>
            <td>
              {{ $currency(booking.discount) }}
              <small v-if="booking.discount_code_used">({{ booking.discount_code_used }})</small>
              <v-btn
                v-else
                color="primary"
                x-small
                outlined
                depressed
                @click="showApplyDiscount = !showApplyDiscount"
              >
               {{ showApplyDiscount ? 'Hide Discount' : 'Apply Discount' }}
              </v-btn>

              <div v-if="showApplyDiscount" class="py-4">
                <v-form
                  ref="form"
                  v-model="validForm"
                >
                  <v-text-field
                    v-model="discountCode"
                    :rules="[v => !!v || 'Discount code is required']"
                    label="Discount Code"
                    clearable
                    autofocus
                    sm
                    outlined
                    required
                    dense
                  />
                  <v-btn
                    :loading="loading"
                    color="primary"
                    x-small
                    depressed
                    @click="applyDiscount"
                  >
                    Apply Discount
                  </v-btn>

                  <FormErrors ref="formErrors" />
                </v-form>
              </div>
            </td>
          </tr>
          <tr>
            <td><strong>VAT (Included)</strong></td>
            <td>{{ $currency(booking.tax) }}</td>
          </tr>
          <tr>
            <td class="total_row primary--text"><strong>Total</strong></td>
            <td class="total_row primary--text">{{ $currency(booking.total) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="mt-6 mb-6" />

    <v-simple-table
      dense
    >
      <template v-slot:default>
        <tbody>
          <tr>
            <td style="width: 150px;"><strong>Paid</strong></td>
            <td>{{ $currency(booking.amount_paid) }}</td>
          </tr>
          <tr>
            <td><strong>Refunded</strong></td>
            <td>{{ $currency(booking.amount_refunded) }}</td>
          </tr>
          <tr>
            <td class="total_row primary--text"><strong>Balance</strong></td>
            <td class="total_row primary--text">{{ $currency(booking.balance) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="mt-6 mb-6" />

    <template v-if="booking.bikes[0]">
      <v-btn
        v-if="booking.bikes[0].invoice_pdf_url"
        :href="booking.bikes[0].invoice_pdf_url"
        target="_blank"
        depressed
        small
      >
        <v-icon
          small
          left
        >
          mdi-receipt
        </v-icon> 
        Dowload Invoice
      </v-btn>
    </template>

    <SnackBar success ref="successBar">
      Discount applied!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ error.message }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'BookingFinanceSummary',

  mixins: [HasBooking, HasForm],

  components: {
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false,
      showApplyDiscount: false,
      discountCode: null,
      error: {}
    }
  },

  computed: {
    sundries () {
      var sundries = 0
      if (this.booking.bikes) {
        this.booking.bikes.forEach(bike => {
          bike.sundries.forEach(sundry => {
            if (!sundry.estimate) {
              if (!sundry.declined) {
                sundries = sundries + sundry.price
              }
            }
          })
        })
      }
      return sundries
    }
  },

  methods: {
    applyDiscount () {
      this.loading = true
      this.$refs.formErrors.clear()
      this.error = {}
      
      this.$api.persist('post', {
        path: `bookings/${this.booking.id}/discount`,
        object: {
          code: this.discountCode
        }
      })
        .then(({ data: data }) => {
          if (data.valid) {
            this.$refs.successBar.open()
            this.discountCode = null
            this.showApplyDiscount = false
          } else {
            var error = { message: data.reason }
            this.error = error
            this.$refs.formErrors.setErrors(error)
          }
        })
        .catch(error => {
          this.error = error
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
<style type="text/css" scoped>
  .total_row {
    font-size: 0.975rem;
    font-weight: bolder;
    padding-top: 10px;
    padding-bottom: 10px;
  }
</style>
