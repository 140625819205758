<template>
  <div>
    <div class="d-flex justify-around">
      <div class="d-block">
        <h2>Booking For {{ booking.customer_name }}</h2>
        <p v-if="booking.id" v-clipboard="booking.id" style="cursor: pointer;"><small>{{ booking.id }}</small></p>
      </div>
      <div class="d-block">
        <v-btn
          v-user-can:UPDATE_USERS
          small
          text
          color="primary"
          @click="editDialog = true"
        >
          Edit
        </v-btn>
      </div>
    </div>

    <v-list-item
      class="mt-2 pl-0"
      three-line
    >
      <v-list-item-content>
        <v-list-item-title>
          <v-chip
            v-if="booking.customer.id"
            :to="{ name: 'customers.show', params: { customerId: booking.customer.id } }"
            outlined
            pill
            small
          >
            <v-icon
              small
              left
            >
              mdi-account-circle
            </v-icon>        
            {{ booking.customer.name }}
          </v-chip>
          <v-chip
            v-if="booking.customer.account"
            outlined
            pill
            small
          >
            <v-icon
              small
              left
            >
              mdi-account-circle
            </v-icon>        
            {{ booking.customer.account.name }}
          </v-chip>
          <template v-if="booking.customer.account">
            <v-chip
              v-if="booking.customer.account.parent"
              outlined
              pill
              small
            >
              <v-icon
                small
                left
              >
                mdi-account-circle
              </v-icon>        
              {{ booking.customer.account.parent.name }}
            </v-chip>
          </template>
        </v-list-item-title>
        <v-list-item-subtitle class="pl-1 mt-1">
          <a
            v-if="booking.job_numbers"
            :href="`https://secure.helpscout.net/mailbox/8e5bcbd51e1903d7/new-ticket?email=${booking.customer_email}&subject=havebike Job ${booking.job_numbers[0]}&body=Hello ${booking.customer.first_name},`"
            target="_blank"
            style="text-decoration: none;"
          >
            {{ booking.customer_email }}
          </a>
          <v-icon
            small
            left
            v-clipboard="booking.customer_email"
            style="cursor: pointer;"
          >
            mdi-content-copy
          </v-icon> 
        </v-list-item-subtitle>
        <v-list-item-subtitle class="pl-1 mt-1">
          <a
            :href="`circleloop: ${booking.customer_mobile}`"
            style="text-decoration: none;"
          >
            {{ booking.customer_mobile }}
          </a>
          <v-icon
            small
            left
            v-clipboard="booking.customer_mobile"
            style="cursor: pointer;"
          >
            mdi-content-copy
          </v-icon> 
        </v-list-item-subtitle>
        <v-list-item-subtitle class="pl-1 mt-1" v-if="booking.customer_phone">
          <a
            :href="`circleloop: ${booking.customer_phone}`"
            style="text-decoration: none;"
          >
            {{ booking.customer_phone }}
          </a>
          <v-icon
            small
            left
            v-clipboard="booking.customer_phone"
            style="cursor: pointer;"
          >
            mdi-content-copy
          </v-icon> 
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-alert
      v-if="booking.po_number"
      dense
      outlined
      text
      type="info"
    >
      This booking has a PO number: <strong>{{ booking.po_number }}</strong>
    </v-alert>

    <CustomerEditDialog
      v-bind="{ booking }"
      :dialog.sync="editDialog"
    />
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import CustomerEditDialog from '@/components/customers/CustomerEditDialog'

export default {
  name: 'BookingCustomerSummary',

  components: {
    CustomerEditDialog
  },

  mixins: [HasBooking],

  data () {
    return {
      editDialog: false
    }
  }
}
</script>
