<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Choose Payment Type</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-row>
            <v-col
              class="text-center"
              style="cursor: pointer;"
              @click="openChargePaymentDialog">
              <v-icon
                color="primary"
                x-large
              >
                mdi-credit-card-sync-outline
              </v-icon>
              <v-divider class="mt-5 mb-5" />
              <h2>Saved Payment Method</h2>
              {{ booking.customer.payment_methods.length }} payment methods on file
            </v-col>
            <v-col
              class="text-center"
              style="cursor: pointer;"
              @click="openNewPaymentMethodDialog">
              <v-icon
                color="primary"
                x-large
              >
                mdi-credit-card-plus-outline
              </v-icon>
              <v-divider class="mt-5 mb-5" />
              <h2>New Payment Method</h2>
            </v-col>
            <v-col
              class="text-center"
              style="cursor: pointer;"
              @click="openPaymentLinkDialog">
              <v-icon
                color="primary"
                x-large
              >
                mdi-link-lock
              </v-icon>
              <v-divider class="mt-5 mb-5" />
              <h2>Payment Link</h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddPaymentLinkDialog
      v-bind="{ booking }"
      :dialog.sync="paymentLinkDialog"
    />

    <ChargePaymentDialog
      v-bind="{ booking }"
      :dialog.sync="chargePaymentDialog"
    />

    <NewPaymentMethodDialog
      v-bind="{ booking }"
      :dialog.sync="newPaymentMethodDialog"
    />
  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import HasDialog from '@/mixins/HasDialog'
import AddPaymentLinkDialog from '@/components/payments/AddPaymentLinkDialog'
import ChargePaymentDialog from '@/components/payments/ChargePaymentDialog'
import NewPaymentMethodDialog from '@/components/payments/NewPaymentMethodDialog'

export default {
  name: 'AddPaymentChooseTypeDialog',

  mixins: [ HasBooking, HasDialog ],

  components: {
    AddPaymentLinkDialog,
    ChargePaymentDialog,
    NewPaymentMethodDialog
  },

  data () {
    return {
      paymentLinkDialog: false,
      chargePaymentDialog: false,
      newPaymentMethodDialog: false
    }
  },

  methods: {
    openNewPaymentMethodDialog () {
      this.closeDialog()
      this.newPaymentMethodDialog = true
    },

    openPaymentLinkDialog () {
      this.closeDialog()
      this.paymentLinkDialog = true
    },

    openChargePaymentDialog () {
      this.closeDialog()
      this.chargePaymentDialog = true
    }
  }
}
</script>
