<template>
  <div>
    <v-btn
      :loading="loading"
      color="error"
      dense
      text
      x-small
      @click="cancelLink"
    >
      Cancel
    </v-btn>

    <SnackBar success ref="successBar">
      Payment link canceled!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ error.message }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import SnackBar from '@/components/SnackBar'

export default {
  name: 'PaymentLinkCancelBtn',

  props: {
    paymentLink: {
      type: Object,
      required: true
    }
  },

  components: {
    SnackBar
  },

  data () {
    return {
      loading: false,
      error: {}
    }
  },

  methods: {
    cancelLink () {
      this.loading = true
      this.$api.delete({
        path: `payment-links/${this.paymentLink.id}`
      })
        .then(() => this.$refs.successBar.open())
        .catch(error => {
          this.error = error
          this.$refs.errorBar.open()
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
