<template>
  <div>
    <ListAndAddCard
      v-if="booking.id"
      :show-add="$auth.userCan('CREATE_BIKES')"
      :path="`bookings/${booking.id}/bikes`"
      :cols="[
        { text: 'Bike', value: 'ref' },
        { text: 'Service', value: 'service' },
        { text: 'W Status', value: 'workshop_status_label' }
      ]"
      :request-params="{ with: ['service', 'asset.servicePlans'], orderBy: 'job_number', ascending: false }"
      :ws-channel="`${booking.id}.booking-bikes`"
      :row-class="(item) => { return { 'bg-red-200': item.job_type === 'JOB_TYPE_C&R_COMPLAINT' } }"
      ws-event="BookingBike"
      title="Bikes"
      icon="mdi-bicycle"
    >
      <template v-slot:item.ref="{ item: bike }">
        <div class="mt-1" style="min-width: 120px;">
          <v-icon x-small left>mdi-wrench</v-icon>

          <strong v-user-can:UPDATE_BIKES @click="editDialog = true; editDialogBike = bike" class="primary--text" style="cursor: pointer;">{{ bike.ref }}</strong>
          <strong v-user-cant:UPDATE_BIKES>{{ bike.ref }}</strong>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small right>mdi-information-outline</v-icon>
            </template>
            <span>{{ bike.name }}</span>
          </v-tooltip>

          <v-icon v-if="bike.job_type === 'JOB_TYPE_C&R_COMPLAINT'" color="red darken-2" small right>mdi-exclamation-thick</v-icon>

          <div v-if="bike.asset" class="mt-1 mb-1">
            <v-divider class="mb-1" />
            <v-icon x-small left>mdi-clipboard-outline</v-icon>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ bike.asset.number }}</span>
              </template>
              <span
                v-for="service_plan in bike.asset.service_plans"
                :key="service_plan.pivot.id"
              >
                {{ service_plan.name }}
              </span>
            </v-tooltip>
            <v-tooltip top v-if="availablePosForBike(bike).includes(booking.po_number)">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="green"
                  small
                  right
                  dense
                >
                  mdi-check-circle
                </v-icon>
              </template>
              PO Number: {{ booking.po_number }} is valid for this service plan.
            </v-tooltip>
            <v-tooltip top v-else-if="bike.asset">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="red"
                  small
                  dense
                  right
                >
                  mdi-close-circle
                </v-icon>
              </template>
              PO Number: {{ booking.po_number }} is not included in this service plan. Please speak to the account manager for {{ booking.customer.account ? booking.customer.account.name : 'this account' }}.
            </v-tooltip>
          </div>
          <div v-if="bike.location_prefix_label || bike.location" class="mt-1 mb-1">
            <v-divider class="mb-1" />
            <v-icon x-small left>mdi-map-marker</v-icon>
            <span>{{ bike.location_prefix_label }} {{ bike.location }}</span>
          </div>
        </div>
      </template>

      <template v-slot:item.service="{ item }">
        <div>
          <span v-if="item.is_complaint">
            Complaint: <strong>{{ item.complaint_category_label }}</strong>
          </span>
          <span v-else>{{ item.service.name }}</span>
        </div>
      </template>

      <template v-slot:item.workshop_status_label="{ item }">
        <v-chip
          color="primary"
          outlined
          pill
          x-small
        >
          {{ item.workshop_status_label }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item: bike }">
        <div style="min-width: 120px;">
          <v-btn
            v-user-can:UPDATE_BIKES
            color="primary"
            x-small
            depressed
            @click="editDialog = true; editDialogBike = bike"
          >
            Edit
          </v-btn>
          <v-btn
            v-user-can:DELETE_BIKES
            v-if="bike.collection_status === 'STATUS_AWAITING_COLLECTION'"
            text
            color="error"
            x-small
            @click="deleteBike(bike)"
          >
            Delete
          </v-btn>
        </div>
      </template>
      <template v-slot:addNew="{ dialog, close }">
        <AddBikeDialog
          :dialog="dialog"
          :booking="booking"
          @update:dialog="close"
        />
      </template>
    </ListAndAddCard>

    <EditBikeDialog
      v-if="editDialog"
      :dialog.sync="editDialog"
      @update:bike="bike => $refs.editBikeSuccessBar.open()"
      v-bind="{ bike: editDialogBike, booking }"
    />

    <SnackBar success ref="editBikeSuccessBar">
      Bike updated successfully!
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import ListAndAddCard from '@/components/ListAndAddCard'
import HasBooking from '@/mixins/HasBooking'
import AddBikeDialog from '@/components/bikes/AddBikeDialog'
import EditBikeDialog from '@/components/bikes/EditBikeDialog'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'BikesList',

  mixins: [ HasBooking ],

  components: {
    ListAndAddCard,
    AddBikeDialog,
    EditBikeDialog,
    SnackBar
  },

  data () {
    return {
      editDialog: false,
      editDialogBike: {}
    }
  },

  methods: {
    availablePosForBike (bike) {
      var pos = []
      if (bike.asset) {
        bike.asset.service_plans.forEach(plan => {
          if (plan.available_po_numbers) {
            plan.available_po_numbers.forEach(po => {
              pos.push(po)
            })
          }
        })
      }
      return pos
    },

    deleteBike (bike) {
      this.$api.delete({
        path: `bookings/${this.booking.id}/bikes/${bike.id}`
      })
      .catch(() => {
        //
      })
    }
  }
}
</script>
<style type="text/css">
  .bg-red-200 {
    background: pink;
  }
</style>
