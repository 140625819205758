<template>
  <div>
    <strong>{{ timelineItem.subject }}</strong>
    <div class="caption">{{ timelineItem.content }}</div>
    <p class="font-weight-medium text--disabled mt-2" style="font-size: 12px;">
      Updated By: {{ timelineItem.created_by ? timelineItem.created_by.name : 'havebike Atlas' }}
      <v-avatar v-if="timelineItem.created_by" size="20" color="primary">
        <img
          :src="timelineItem.created_by.profile_img_url"
          :alt="timelineItem.created_by.name">
      </v-avatar>
    </p>
  </div>
</template>
<script>
import HasTimelineItem from '@/mixins/HasTimelineItem'

export default {
  name: 'BookingTimelineItemModelUpdate',

  mixins: [ HasTimelineItem ],

  data () {
    return {
      dialog: false
    }
  }
}
</script>
