<template>
  <div>
    <div
      v-for="(bike, key) in orderedBikes"
      :key="bike.id"
      :class="{ 'mt-2': key !== 0 }"
    >
      <ListAndAddCard
        :show-add="$auth.userCan('CREATE_BOOKING_SUNDRIES') && key === 0"
        :path="`bookings/${booking.id}/sundries`"
        :request-params="{ with: ['bookingBike', 'createdBy', 'receivedBy', 'createdBy', 'supplier', 'product', 'orderedBy'], orderBy: 'created_at', ascending: false, bikeId: bike.id }"
        :cols="[
          { text: 'Part / Labour', value: 'name' },
          { text: 'Price', value: 'price' },
          { text: 'Created', value: 'created_by' }
        ]"
        :ws-channel="`${booking.id}.booking-sundries`"
        :title="key === 0 ? 'Sundries' : ''"
        :subtitle="`${bike.ref} ${bike.asset ? '[' + bike.asset.number + ']' : ''} - (${bike.manafacturer} ${bike.model})`"
        :icon="key === 0 ? 'mdi-progress-wrench' : null"
        :show-refresh="key === 0 ? true : false"
        ws-event="BookingSundry"
      >
        <template v-slot:item.created_by="{ item: sundry }">
          <v-tooltip top v-if="sundry.created_by">
            <template v-slot:activator="{ on }">
              <v-avatar size="24" color="primary" v-on="on">
                <img
                  v-if="sundry.created_by.profile_img_url"
                  :src="sundry.created_by.profile_img_url"
                  :alt="sundry.created_by.name">
                <strong v-else class="white--text" style="font-size: 10px;">{{ sundry.created_by.initials }}</strong>
              </v-avatar>
            </template>
            <span>Created at {{ $momentDateTimeHuman(sundry.created_at) }} by {{ sundry.created_by.name }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.name="{ item: sundry }">
          <div class="mt-1">
            <div class="d-flex justify-space-between">
              <span class="font-weight-bold">
                <span
                  v-user-can:UPDATE_BOOKING_SUNDRIES
                  :class="{
                    'primary--text': true,
                    'sundry_name_declined': sundry.declined
                  }"
                  style="cursor: pointer;"
                  @click="dialog = true; dialogSundry = sundry"
                >
                  {{ sundry.trimmed_name }}
                </span>
                <span
                  v-user-cant:UPDATE_BOOKING_SUNDRIES
                  :class="{
                    'sundry_name_declined': sundry.declined
                  }"
                >
                  {{ sundry.trimmed_name }}
                </span>
                <span v-if="sundry.qty > 1">&nbsp;x&nbsp;{{ sundry.qty }}</span>
              </span>

              <div class="d-flex pl-2">

                <v-tooltip v-if="!sundry.estimate && !sundry.declined" top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="green" small right>mdi-check-circle-outline</v-icon>
                  </template>
                  <span>This sundry has been approved by the customer.</span>
                </v-tooltip>
                
                <v-tooltip v-if="sundry.safety_critical" top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="red darken-2" small right>mdi-alert-circle</v-icon>
                  </template>
                  <span>This sundry has been marked as safety critical.</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small right>mdi-information-outline</v-icon>
                  </template>
                  <span>{{ sundry.description }}</span>
                </v-tooltip>
              </div>
            </div>

            <div class="mt-1 mb-1">
              <v-divider class="mb-1" />
              <template v-if="sundry.declined">
                <small>
                  <v-icon x-small left>mdi-close-circle</v-icon>
                  <strong class="red--text">Declined</strong>
                </small>
              </template>
              <template v-else>
                <small v-if="sundry.estimate && bike.workshop_status === 'STATUS_WORK_UNDERWAY'">
                  <strong class="orange--text">Awaiting Approval - </strong>
                </small>
                <span v-if="!sundry.is_labour">
                  <small v-if="sundry.in_stock" class="green--text">
                    <v-icon x-small left>mdi-check-circle</v-icon>
                    <strong>In stock</strong>
                    <a v-if="sundry.stock_product_id" :href="`${$telescope.url}/products/${sundry.stock_product_id}`" target="_blank" style="text-decoration: none;">
                      <v-icon x-small right>mdi-open-in-new</v-icon>
                    </a>
                  </small>
                  <small v-if="sundry.ordered_at" :class="{ 'green--text': sundry.received_at }">
                    <v-icon x-small left>mdi-truck-fast</v-icon>
                    <strong>Ordered on {{ $momentDateNoYear(sundry.ordered_at) }}<span v-if="sundry.estimated_delivery_date && !sundry.received_at">&nbsp;& expected on {{ $momentDateNoYear(sundry.estimated_delivery_date) }}</span><span v-if="sundry.received_at">&nbsp;& received on {{ $momentDateNoYear(sundry.received_at) }}</span></strong>
                  </small>
                  <small v-else-if="!sundry.in_stock">
                    <v-icon x-small left>mdi-truck</v-icon>
                    <strong>Not ordered<span v-if="sundry.estimated_delivery_date">&nbsp;-&nbsp;expected delivery on {{ $momentDateNoYear(sundry.estimated_delivery_date) }}</span>
                    </strong>
                    <a v-if="sundry.stock_product_id" :href="`${$telescope.url}/products/${sundry.stock_product_id}`" target="_blank" style="text-decoration: none;">
                      <v-icon x-small right>mdi-open-in-new</v-icon>
                    </a>
                  </small>
                </span>
                <span v-else>
                  <v-icon x-small left>mdi-wrench</v-icon>
                  <small><strong class="green--text">Labour</strong></small>
                </span>
              </template>
              <div v-if="sundry.location_name && (sundry.in_stock || sundry.received_at)">
                <v-icon x-small left>mdi-map-marker</v-icon>
                <small><strong>{{ sundry.location_name }}</strong></small>
                <a :href="`${$telescope.url}/products/${sundry.stock_product_id}`" target="_blank" style="text-decoration: none;">
                  <v-icon x-small right>mdi-open-in-new</v-icon>
                </a>
              </div>
            </div>

          </div>
        </template>

        <template v-slot:item.price="{ item }">
          <span v-if="! item.warranty_id">{{ $currency(item.price) }}</span>
          <s v-else>{{ $currency(item.price) }}</s>
          <div v-if="item.warranty_id"><strong class="red--text"><small>warranty</small></strong></div>
        </template>

        <template v-slot:addNew="{ dialog, close }">
          <AddSundryDialog
            :dialog="dialog"
            :booking="booking"
            @update:dialog="close"
          />
        </template>
      </ListAndAddCard>
    </div>

    <EditSundryDialog
      v-if="dialogSundry"
      :dialog.sync="dialog"
      :sundry="dialogSundry"
      :booking="booking"
    />
  </div>
</template>
<script type="text/javascript">
import AddSundryDialog from '@/components/sundries/AddSundryDialog'
import ListAndAddCard from '@/components/ListAndAddCard'
import HasBooking from '@/mixins/HasBooking'
import EditSundryDialog from '@/components/sundries/EditSundryDialog'
import orderBy from 'lodash.orderby'

export default {
  name: 'SundriesList',

  mixins: [ HasBooking ],

  components: {
    AddSundryDialog,
    ListAndAddCard,
    EditSundryDialog
  },

  data () {
    return {
      editSundryDialog: false,
      editableSundry: null,
      dialogSundry: {},
      dialog: false
    }
  },

  computed: {
    orderedBikes () {
      return orderBy(this.booking.bikes, 'ref', 'desc')
    }
  }
}
</script>
<style scoped>
  .sundry_name_declined {
    text-decoration: line-through;
  }
</style>
