var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.bike.ref)+" "),(_vm.bike.asset)?_c('strong',[_vm._v(" / "+_vm._s(_vm.bike.asset.number)+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","small":"","dense":""}},on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.bike.name))])])],1),_c('td',[_vm._v(" "+_vm._s(_vm.collection ? _vm.bike.collection_status_label : _vm.bike.return_status_label)+" ")]),_c('td',[(_vm.manifest)?_c('router-link',{attrs:{"to":{ name: 'manifests.show', params: { manifestId: _vm.manifest.id } }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.manifest)?_c('v-avatar',_vm._g({staticClass:"mt-1",attrs:{"color":"primary","size":"25"}},on),[(_vm.manifest.driver.profile_img_url)?_c('img',{attrs:{"src":_vm.manifest.driver.profile_img_url,"alt":_vm.manifest.driver.name}}):_c('strong',{staticClass:"white--text",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm.manifest.driver.initials))])]):_vm._e()]}}],null,false,3968258417)},[(_vm.manifestPivot)?_c('span',[_vm._v("Driver: "+_vm._s(_vm.manifest.driver.name)+" | Run Order: "+_vm._s(_vm.manifestPivot.order))]):_vm._e()])],1):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.manifest)?_c('v-btn',_vm._g(_vm._b({directives:[{name:"user-can",rawName:"v-user-can:DELETE_BOOKING_BIKE_MANIFESTS",arg:"DELETE_BOOKING_BIKE_MANIFESTS"}],attrs:{"text":"","color":"error","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" Remove ")]):_vm._e()]}}]),model:{value:(_vm.removeManifestDialog),callback:function ($$v) {_vm.removeManifestDialog=$$v},expression:"removeManifestDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Remove From Manifest ")]),_c('v-card-text',{staticClass:"pt-2"},[_vm._v(" Are you sure you want to remove "+_vm._s(_vm.bike.ref)+" from this manifest? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.removeManifestDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.manifestDeleteLoading},on:{"click":_vm.removeJobFromManifest}},[_vm._v(" Remove From Manifest ")])],1)],1)],1)],1),_c('td',[(_vm.revisedDate)?_c('strong',[_vm._v(" "+_vm._s(_vm.$momentDate(_vm.revisedDate))+" ")]):_vm._e(),_c('span',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","outlined":"","x-small":""},on:{"click":function($event){_vm.rearrangeDatesDialog = true}}},[_vm._v(" Re-Schedule ")])],1)]),_c('td',[(_vm.manifestPivot.cr_at)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success","small":"","dense":""}},on),[_vm._v(" mdi-check-circle-outline ")])]}}],null,false,3888644937)},[_c('span',[_vm._v(_vm._s(_vm.$momentDateTimeHuman(_vm.manifestPivot.cr_at))+" by "+_vm._s(_vm.manifestPivot.cr_by ? _vm.manifestPivot.cr_by.name : ' - '))])]):_c('span',[_vm._v(" - ")])],1),(_vm.collection)?_c('td',[(_vm.showWorkshopBtn)?_c('v-btn',{attrs:{"loading":_vm.loading,"color":"success","outlined":"","x-small":""},on:{"click":_vm.markAs}},[_vm._v(" Mark Arrived ")]):(_vm.manifestPivot.workshop_at)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success","small":"","dense":""}},on),[_vm._v(" mdi-check-circle-outline ")])]}}],null,false,3888644937)},[_c('span',[_vm._v(_vm._s(_vm.$momentDateTimeHuman(_vm.manifestPivot.workshop_at)))])]):[_vm._v("-")]],2):_vm._e(),_c('BikeReArrangeDatesDialog',_vm._b({attrs:{"dialog":_vm.rearrangeDatesDialog},on:{"update:dialog":function($event){_vm.rearrangeDatesDialog=$event}}},'BikeReArrangeDatesDialog',{ bike: _vm.bike, booking: _vm.booking },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }