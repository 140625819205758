<template>
  <div>
    <div v-user-can:READ_BOOKINGS_WORKSHOP>
      <BookingBikeSelector
        v-model="bikeId"
        :booking="booking"
      />

<!--       <v-btn
        depressed
        small
        @click="triageDialog = true"
      >
        Triage Dialog
      </v-btn> -->

<!--       <TriageDialog
        :dialog.sync="triageDialog"
        :bike="bike"
        :booking="booking"
        :service-record="triageServiceRecord"
      /> -->

<!--       <v-btn
        :href="bike.safety_check_pdf_url"
        depressed
        small
      >
        Safety Check PDF
        <v-icon
          right
          dense
        >
          mdi-file-download
        </v-icon>
      </v-btn>

      <v-btn

        :href="bike.service_record_pdf_url"
        class="mt-2 ml-2"
        depressed
        small
      >
        Service Record PDF
        <v-icon
          right
          dense
        >
          mdi-file-download
        </v-icon>
      </v-btn> -->

      <v-row class="mt-2">
        <v-col>
          <v-alert
            dense
            outlined
            color="primary"
          >
            <h2>{{ bike.service.name }}</h2>
            <p>
              <strong>To Be Worked On (TBO) Date:</strong>
              {{ $momentDate(bike.planned_service_date) }}
            </p>
            <v-btn
              v-user-can:UPDATE_BIKES_TBO_DATE
              :loading="loading"
              color="primary"
              dark
              depressed
              small
              @click="tboDialog = true"
            >
              Schedule TBO Date
            </v-btn>

            <ScheduleTBWDateDialog
              v-user-can:UPDATE_BIKES_TBO_DATE
              :dialog.sync="tboDialog"
              :bike="bike"
            />

            <v-divider class="my-4" />

            <NoteBox v-if="bike.customer_notes" :data="{ type: 'TYPE_CUSTOMER_NOTE' }">
              {{ bike.customer_notes }}
            </NoteBox>
            <p v-else>
              No customer notes to show
            </p>

            <v-divider class="my-4" />

            <v-alert
              v-if="bike.available_workshop_statuses.includes('STATUS_AWAITING_TRIAGE') && !bike.available_workshop_statuses.includes('STATUS_TRIAGE_COMPLETED')"
              dense
              text
              outlined
              type="warning"
            >
              This bike still hasn't arrived into the workshop.
            </v-alert>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_WORK_UNDERWAY')"
              :loading="loading"
              color="green"
              class="mr-2"
              dark
              depressed
              small
              @click="updateBike('STATUS_WORK_UNDERWAY')"
            >
              Start Service / Work
              <v-icon
                right
                small
                dense
              >
                mdi-wrench-outline
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_PARTS_ARRIVED')"
              :loading="loading"
              color="green"
              class="mr-2"
              dark
              depressed
              small
              @click="updateBike('STATUS_PARTS_ARRIVED')"
            >
              Parts Arrived
              <v-icon
                right
                dense
              >
                mdi-truck-fast-outline
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_TRIAGE_STARTED')"
              :loading="loading"
              class="mr-2"
              depressed
              small
              @click="updateBike('STATUS_TRIAGE_STARTED')"
            >
              Begin Triage
              <v-icon
                right
                dense
              >
                mdi-flag-checkered
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_WASH_STARTED_NO_TRIAGE')"
              :loading="loading"
              depressed
              small
              @click="updateBike('STATUS_WASH_STARTED_NO_TRIAGE')"
            >
              Begin Clean
              <v-icon
                right
                dense
              >
                mdi-hand-wash
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_WASH_STARTED_TRIAGE_COMPLETE')"
              :loading="loading"
              depressed
              small
              class="mr-2"
              @click="updateBike('STATUS_WASH_STARTED_TRIAGE_COMPLETE')"
            >
              Begin Clean
              <v-icon
                right
                dense
              >
                mdi-hand-wash
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_TRIAGE_CONFIRMED')"
              :loading="loading"
              depressed
              small
              @click="dialog = true"
            >
              Confirm Triage
              <v-icon
                right
                dense
              >
                mdi-flag-checkered
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_WASH_COMPLETED_NO_TRIAGE')"
              :loading="loading"
              depressed
              small
              @click="updateBike('STATUS_WASH_COMPLETED_NO_TRIAGE')"
            >
              Complete Clean
              <v-icon
                right
                dense
              >
                mdi-hand-wash
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_WASH_COMPLETED_TRIAGE_COMPLETE')"
              :loading="loading"
              depressed
              small
              @click="updateBike('STATUS_WASH_COMPLETED_TRIAGE_COMPLETE')"
            >
              Complete Clean
              <v-icon
                right
                dense
              >
                mdi-hand-wash
              </v-icon>
            </v-btn>

            <v-alert
              v-if="bike.available_workshop_statuses.includes('STATUS_TRIAGE_COMPLETED')"
              dense
              text
              outlined
              type="warning"
            >
              Please add any sundries to this booking before marking triage as complete. Remember to add them as <strong>estimates.</strong>
            </v-alert>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_AWAITING_TRIAGE') && bike.available_workshop_statuses.includes('STATUS_TRIAGE_COMPLETED')"
              class="mr-2"
              :loading="loading"
              depressed
              small
              @click="updateBike('STATUS_AWAITING_TRIAGE')"
            >
              Abort Triage
              <v-icon
                right
                dense
              >
                mdi-flag
              </v-icon>
            </v-btn>
            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_TRIAGE_COMPLETED')"
              :loading="loading"
              depressed
              small
              @click="unapprovedSundries.length === 0 ? updateBike('STATUS_TRIAGE_COMPLETED') : checkTriageDialog = true"
            >
              Complete Triage
              <v-icon
                right
                dense
              >
                mdi-flag-checkered
              </v-icon>
            </v-btn>

            <CompleteTriageDialog
              v-if="bike.available_workshop_statuses.includes('STATUS_TRIAGE_CONFIRMED')"
              v-bind="{ bike, booking }"
              :key="`${bike.id}-complete`"
              :dialog.sync="dialog"
              @update:bike="bike => updateBike(bike.workshop_status)"
            />
            <v-btn
              v-if="unapprovedSundries.length > 0 && !bike.available_workshop_statuses.includes('STATUS_TRIAGE_CONFIRMED') && !bike.available_workshop_statuses.includes('STATUS_TRIAGE_COMPLETED')"
              :loading="loading"
              depressed
              small
              @click="sundriesDialog = true"
            >
              Approve Extra Sundries
              <v-icon
                right
                dense
              >
                mdi-flag-checkered
              </v-icon>
            </v-btn>

            <ApproveSundriesDialog
              v-if="unapprovedSundries.length > 0"
              v-bind="{ bike, booking }"
              :key="`${bike.id}-approve`"
              :dialog.sync="sundriesDialog"
              @update:bike="bike => updateBike(bike.workshop_status)"
            />

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_AWAITING_QC')"
              :loading="loading"
              color="primary"
              class="mr-2"
              depressed
              small
              @click="updateBike('STATUS_AWAITING_QC')"
            >
              Work Complete / Awaiting QC
              <v-icon
                right
                dense
              >
                mdi-flag-checkered
              </v-icon>
            </v-btn>

            <v-btn
              v-if="bike.available_workshop_statuses.includes('STATUS_AWAITING_PARTS')"
              :loading="loading"
              depressed
              small
              @click="updateBike('STATUS_AWAITING_PARTS')"
            >
              Awaiting Parts
              <v-icon
                right
                dense
              >
                mdi-truck-fast-outline
              </v-icon>
            </v-btn>

            <v-alert
              v-if="bike.available_workshop_statuses.includes('STATUS_CANCELLED')"
              class="mt-4"
              color="red"
              outlined
              dense
              text
            >
              <v-btn
                :loading="loading"
                depressed
                small
                color="red"
                dark
                @click="updateBike('STATUS_CANCELLED')"
              >
                Cancel Job
                <v-icon
                  right
                  dense
                >
                  mdi-close-circle-outline
                </v-icon>
              </v-btn>
            </v-alert>

            <v-alert
              v-if="bike.available_workshop_statuses.includes('STATUS_QC_STARTED')"
              dense
              text
              outlined
              type="warning"
            >
              This bike has now gone to QC. Please see the QC tab to continue.
            </v-alert>

          </v-alert>
        </v-col>
      </v-row>

      <v-alert
        v-if="booking.workshop_notes.length > 0"
        v-user-can:READ_BOOKINGS_TIMELINE
        :loading="loading"
        type="warning"
        outlined
      >
        <v-card-text>
          <v-timeline
            class="mt-2"
            dense
            clipped
          >
            <v-timeline-item
              v-for="note in booking.workshop_notes"
              :key="note.id"
              right
              small
            >
              <v-row class="pt-1">
                <v-col cols="3">
                  <strong>{{ $momentDateTimeHuman(note.created_at) }}</strong>
                </v-col>
                <v-col>
                  <NoteBox :data="note">
                    {{ note.content }}
                    <template #footer>
                      <small class="d-block text-right font-weight-medium mt-2">
                        Noted By: {{ note.created_by.name }}
                        <v-avatar size="20" color="primary">
                          <img
                            :src="note.created_by.profile_img_url"
                            :alt="note.created_by.name">
                        </v-avatar>
                      </small>
                    </template>
                  </NoteBox>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-alert>
    </div>
    <v-alert
      v-user-cant:READ_BOOKINGS_WORKSHOP
      dense
      text
      outlined
      type="warning"
    >
      You don't have permission to view the workshop section.
    </v-alert>

    <SnackBar ref="errorBar" error>
      {{ error.message }}
    </SnackBar>
    <SnackBar ref="successBar" success>
      Bike updated successfully!
    </SnackBar>

    <CheckTriageDialog
      v-if="bike.available_workshop_statuses.includes('STATUS_TRIAGE_COMPLETED')"
      v-bind="{ bike, booking }"
      :key="`${bike.id}-check-triage`"
      :dialog.sync="checkTriageDialog"
      @confirmed="updateBike('STATUS_TRIAGE_COMPLETED')"
    />

  </div>
</template>
<script type="text/javascript">
import HasBooking from '@/mixins/HasBooking'
import BookingBikeSelector from '@/components/bookings/BookingBikeSelector'
import HasBikeSelected from '@/mixins/HasBikeSelected'
import HasServiceRecords from '@/mixins/HasServiceRecords'
import SnackBar from '@/components/SnackBar'
import CompleteTriageDialog from '@/components/bookings/CompleteTriageDialog'
// import TriageDialog from '@/components/bookings/TriageDialog'
import ScheduleTBWDateDialog from '@/components/bookings/ScheduleTBWDateDialog'
import ApproveSundriesDialog from '@/components/bookings/ApproveSundriesDialog'
import CheckTriageDialog from '@/components/bookings/CheckTriageDialog'
import NoteBox from '@/components/notes/NoteBox'

export default {
  name: 'BookingWorkshop',

  mixins: [ HasBooking, HasBikeSelected, HasServiceRecords ],

  components: {
    BookingBikeSelector,
    SnackBar,
    CompleteTriageDialog,
    // TriageDialog,
    ScheduleTBWDateDialog,
    ApproveSundriesDialog,
    CheckTriageDialog,
    NoteBox
  },

  data () {
    return {
      error: {},
      checkTriageDialog: false,
      tboDialog: false,
      loading: false,
      dialog: false,
      triageDialog: false,
      sundriesDialog: false,
      showPlannedServiceDateModal: false
    }
  },

  watch: {
    files () {
      this.updateBookingBike()
    }
  },

  computed: {
    job () {
      return this.bike
    }
  },

  methods: {
    updateBookingBike () {
      this.loading = true
      this.$api.persist('put', {
        path: `bookings/${this.booking.id}/bikes/${this.bike.id}`,
        object: this.bike
      })
        .then(() => {
          //
        })
        .catch(() => {
          // this.$refs.formErrors.setErrors(error)
          // this.$refs.errorBar.open()
          // this.error = error
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
<style type="text/css" scoped="">
  .img-preview {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 7px;
  }
</style>§
