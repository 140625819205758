var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.booking.id)?_c('ListAndAddCard',{attrs:{"show-add":_vm.$auth.userCan('CREATE_BIKES'),"path":("bookings/" + (_vm.booking.id) + "/bikes"),"cols":[
      { text: 'Bike', value: 'ref' },
      { text: 'Service', value: 'service' },
      { text: 'W Status', value: 'workshop_status_label' }
    ],"request-params":{ with: ['service', 'asset.servicePlans'], orderBy: 'job_number', ascending: false },"ws-channel":((_vm.booking.id) + ".booking-bikes"),"row-class":function (item) { return { 'bg-red-200': item.job_type === 'JOB_TYPE_C&R_COMPLAINT' } },"ws-event":"BookingBike","title":"Bikes","icon":"mdi-bicycle"},scopedSlots:_vm._u([{key:"item.ref",fn:function(ref){
    var bike = ref.item;
return [_c('div',{staticClass:"mt-1",staticStyle:{"min-width":"120px"}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-wrench")]),_c('strong',{directives:[{name:"user-can",rawName:"v-user-can:UPDATE_BIKES",arg:"UPDATE_BIKES"}],staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.editDialog = true; _vm.editDialogBike = bike}}},[_vm._v(_vm._s(bike.ref))]),_c('strong',{directives:[{name:"user-cant",rawName:"v-user-cant:UPDATE_BIKES",arg:"UPDATE_BIKES"}]},[_vm._v(_vm._s(bike.ref))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","right":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(bike.name))])]),(bike.job_type === 'JOB_TYPE_C&R_COMPLAINT')?_c('v-icon',{attrs:{"color":"red darken-2","small":"","right":""}},[_vm._v("mdi-exclamation-thick")]):_vm._e(),(bike.asset)?_c('div',{staticClass:"mt-1 mb-1"},[_c('v-divider',{staticClass:"mb-1"}),_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-clipboard-outline")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(bike.asset.number))])]}}],null,true)},_vm._l((bike.asset.service_plans),function(service_plan){return _c('span',{key:service_plan.pivot.id},[_vm._v(" "+_vm._s(service_plan.name)+" ")])}),0),(_vm.availablePosForBike(bike).includes(_vm.booking.po_number))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green","small":"","right":"","dense":""}},on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_vm._v(" PO Number: "+_vm._s(_vm.booking.po_number)+" is valid for this service plan. ")]):(bike.asset)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red","small":"","dense":"","right":""}},on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_vm._v(" PO Number: "+_vm._s(_vm.booking.po_number)+" is not included in this service plan. Please speak to the account manager for "+_vm._s(_vm.booking.customer.account ? _vm.booking.customer.account.name : 'this account')+". ")]):_vm._e()],1):_vm._e(),(bike.location_prefix_label || bike.location)?_c('div',{staticClass:"mt-1 mb-1"},[_c('v-divider',{staticClass:"mb-1"}),_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-map-marker")]),_c('span',[_vm._v(_vm._s(bike.location_prefix_label)+" "+_vm._s(bike.location))])],1):_vm._e()],1)]}},{key:"item.service",fn:function(ref){
    var item = ref.item;
return [_c('div',[(item.is_complaint)?_c('span',[_vm._v(" Complaint: "),_c('strong',[_vm._v(_vm._s(item.complaint_category_label))])]):_c('span',[_vm._v(_vm._s(item.service.name))])])]}},{key:"item.workshop_status_label",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","outlined":"","pill":"","x-small":""}},[_vm._v(" "+_vm._s(item.workshop_status_label)+" ")])]}},{key:"item.actions",fn:function(ref){
    var bike = ref.item;
return [_c('div',{staticStyle:{"min-width":"120px"}},[_c('v-btn',{directives:[{name:"user-can",rawName:"v-user-can:UPDATE_BIKES",arg:"UPDATE_BIKES"}],attrs:{"color":"primary","x-small":"","depressed":""},on:{"click":function($event){_vm.editDialog = true; _vm.editDialogBike = bike}}},[_vm._v(" Edit ")]),(bike.collection_status === 'STATUS_AWAITING_COLLECTION')?_c('v-btn',{directives:[{name:"user-can",rawName:"v-user-can:DELETE_BIKES",arg:"DELETE_BIKES"}],attrs:{"text":"","color":"error","x-small":""},on:{"click":function($event){return _vm.deleteBike(bike)}}},[_vm._v(" Delete ")]):_vm._e()],1)]}},{key:"addNew",fn:function(ref){
    var dialog = ref.dialog;
    var close = ref.close;
return [_c('AddBikeDialog',{attrs:{"dialog":dialog,"booking":_vm.booking},on:{"update:dialog":close}})]}}],null,false,3723468785)}):_vm._e(),(_vm.editDialog)?_c('EditBikeDialog',_vm._b({attrs:{"dialog":_vm.editDialog},on:{"update:dialog":function($event){_vm.editDialog=$event},"update:bike":function (bike) { return _vm.$refs.editBikeSuccessBar.open(); }}},'EditBikeDialog',{ bike: _vm.editDialogBike, booking: _vm.booking },false)):_vm._e(),_c('SnackBar',{ref:"editBikeSuccessBar",attrs:{"success":""}},[_vm._v(" Bike updated successfully! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }