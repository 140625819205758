<template>
  <tr
    :class="{'blue lighten-4': sundry.is_labour}"
  >
    <td class="py-1">
      <PartsToOrderPartColumn :sundry="sundry" />
    </td>
    <td>
      <v-alert v-if="sundry.is_labour" type="warning" class="mt-2" text dense outlined>
        <small>This labour doesn't have a part.</small>
        <v-btn
          color="warning"
          depressed
          x-small
          class="ml-2"
          @click="$emit('addAssociatedSundry', sundry)"
        >
          Add Sundry
        </v-btn>
      </v-alert>

      <v-alert v-if="isPartNoLabour" type="warning" class="mt-2" text dense outlined>
        <small>This part doesn't have any labour.</small>
        <v-btn
          color="warning"
          depressed
          x-small
          class="ml-2"
          @click="$emit('addAssociatedSundry', sundry)"
        >
          Add Sundry
        </v-btn>
      </v-alert>
      <v-simple-table>
        <thead>
          <tr
            :class="{'blue lighten-4': sundry.is_labour}"
          >
            <td>
              <v-btn x-small depressed @click.stop="editDialog = true">Edit</v-btn>
              {{ sundry.trimmed_name }} <small class="red--text font-weight-bold">{{ sundry.qty > 1 ? `(x ${sundry.qty})` : null }}</small>
            </td>
            <td :class="{'text-left': true, 'green--text darken-2': !sundry.estimate}" style="width: 90px;">
              <span v-if="! sundry.warranty_id">{{ $currency(sundry.price) }}</span>
              <s v-else>{{ $currency(sundry.price) }}</s>
              <div v-if="sundry.warranty_id"><strong class="red--text"><small>warranty</small></strong></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <AssociatedSundryReviewRow
            v-for="associatedSundry in sundry.associated_sundries"
            :key="associatedSundry.id"
            :sundry="associatedSundry"
            :booking="booking"
          />
          <tr :class="{'blue lighten-4': sundry.is_labour}">
            <td class="font-weight-black">Sub-Total</td>
            <td :class="{'font-weight-black': true}">{{ $currency(sundrySubtotal(sundry)) }}</td>
          </tr>
        </tbody>
      </v-simple-table>

      <EditSundryDialog
        v-if="booking"
        :key="sundry.id"
        :dialog.sync="editDialog"
        :sundry="sundry"
        :booking="booking"
      />
    </td>
  </tr>
</template>
<script>
import HasSundry from '@/mixins/HasSundry'
import HasBooking from '@/mixins/HasBooking'
import HasSundryCalculations from '@/mixins/HasSundryCalculations'
import PartsToOrderPartColumn from '@/components/dashboard/PartsToOrderPartColumn'
import AssociatedSundryReviewRow from '@/components/sundries/AssociatedSundryReviewRow'
import EditSundryDialog from '@/components/sundries/EditSundryDialog'

export default {
  name: 'SundryReviewRow',

  mixins: [HasSundry, HasSundryCalculations, HasBooking],

  components: {
    PartsToOrderPartColumn,
    EditSundryDialog,
    AssociatedSundryReviewRow
  },

  data () {
    return {
      editDialog: false
    }
  },

  computed: {
    isPartNoLabour () {
      if (!this.sundry.is_labour) {
        var noLabour = true
        this.sundry.associated_sundries.forEach(associatedSundry => {
          if (associatedSundry.is_labour) {
            noLabour = false
          }
        })

        return noLabour
      }
      return false
    }
  }
}
</script>
