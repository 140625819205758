<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="1200px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Check Triage</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-row>
            <v-col sm="8" cols="12">
              <v-alert
                dense
                text
                outlined
                type="warning"
              >
                Please ensure all sundries are correct, have the relevent information and correct pricing before confirming
              </v-alert>

              <v-btn
                color="primary"
                depressed
                :loading="loading"
                @click.stop="defaultAssociatedSundry = null; addSundryDialog = true"
              >
                Add New Sundry
              </v-btn>

              <v-divider class="mt-3" />

              <v-simple-table
                v-if="sundries.length > 0"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Part / Labour</th>
                      <th class="text-left">Associated Items</th>
                    </tr>
                  </thead>
                  <tbody>
                    <SundryReviewRow
                      v-for="sundry in sundries"
                      :key="sundry.id"
                      :sundry="sundry"
                      :booking="booking"
                      @sundry:updated="getSundries"
                      @addAssociatedSundry="defaultAssociatedSundry = $event; addSundryDialog = true;"
                    />
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col class="iframeCol" sm="4" cols="12">
              <v-alert
                dense
                text
                outlined
                type="info"
              >
                Below is the email the customer will receive
              </v-alert>
              <iframe ref="previewIframe" :src="bike.signed_email_previews['customer-considering']" width="100%" height="800px" frameborder="0" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" :loading="loading" text @click="closeDialog(); $emit('confirmed')"> Confirm Triage <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddSundryDialog
      :dialog.sync="addSundryDialog"
      :booking="booking"
      :default-bike="bike"
      :default-associated-sundry="defaultAssociatedSundry"
      :default-is-part="(defaultAssociatedSundry ? defaultAssociatedSundry.id : false) && (defaultAssociatedSundry ? defaultAssociatedSundry.is_labour : false)"
      :default-is-labour="(defaultAssociatedSundry ? defaultAssociatedSundry.id : false) && (defaultAssociatedSundry ? !defaultAssociatedSundry.is_labour : false)"
    />
  </div>
</template>
<script>
import HasDialog from '@/mixins/HasDialog'
import HasBooking from '@/mixins/HasBooking'
import HasBike from '@/mixins/HasBike'
import HasForm from '@/mixins/HasForm'
import SundryReviewRow from '@/components/sundries/SundryReviewRow'
import AddSundryDialog from '@/components/sundries/AddSundryDialog'
import HasSundryCalculations from '@/mixins/HasSundryCalculations'

  export default {
    name: 'CheckTriageDialog',

    mixins: [
      HasDialog,
      HasBooking,
      HasBike,
      HasForm,
      HasSundryCalculations
    ],

    components: {
      SundryReviewRow,
      AddSundryDialog
    },

    data () {
      return {
        loading: false,
        addSundryDialog: false,
        sundries: [],
        defaultAssociatedSundry: null
      }
    },

    watch: {
      dialog: {
        immediate: true,
        handler () {
          this.getSundries()
        }
      }
    },

    mounted () {
      if (window.Echo) {
        window.Echo.private(`${this.booking.id}.booking-sundries`)
          .listen('BookingSundryCreated', () => {
            this.getSundries()
            this.reloadIframe()
          })
          .listen('BookingSundryUpdated', () => {
            this.getSundries()
            this.reloadIframe()
          })
          .listen('BookingSundryDeleted', () => {
            this.getSundries()
            this.reloadIframe()
          })
      }
    },

    methods: {
      reloadIframe () {
        if (this.dialog) {
          this.$refs.previewIframe.src += ' '          
        }
      }
    }
  }
</script>
<style type="text/css" scoped>
  .iframeCol {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }
</style>