import find from 'lodash.find'
import filter from 'lodash.filter'

export default {
  data () {
    return {
      bikeId: null
    }
  },

  mounted () {
    if (this.booking.bikes.length > 0) {
      this.bikeId = window.localStorage.getItem(`hb_booking_${this.booking.id}_bike`) || this.booking.bikes[0].id
    }
  },

  watch: {
    bikeId: function () {
      this.bikeChanged()
    }
  },

  computed: {
    bike () {
      return find(this.booking.bikes, { id: this.bikeId }) ?? {
        service: {},
        available_workshop_statuses: []
      }
    },

    unapprovedSundries () {
      return filter(this.bike.sundries, {
        declined: false,
        estimate: true
      })
    }
  },

  methods: {
    bikeChanged () {
      //
    },

    updateBike (workshop_status) {
      this.loading = true
      this.$api.persist('put', {
        path: `bookings/${this.booking.id}/bikes/${this.bike.id}`,
        object: {
          ...this.bike,
          ...{workshop_status}
        }
      })
        .then(() => {
          this.$refs.successBar.open()
          this.error = {}
        })
        .catch(error => { 
          this.error = error
          this.$refs.errorBar.open()
        })
        .finally(() => this.loading = false)
    }
  }
}