<template>
  <div>
    <v-btn
      v-if="!paymentLink.is_paid"
      :loading="loading"
      color="primary"
      dense
      depressed
      x-small
      @click="sendNudge"
    >
      Nudge
    </v-btn>

    <SnackBar success ref="successBar">
      Payment link nudge sent!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ error.message }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import SnackBar from '@/components/SnackBar'

export default {
  name: 'PaymentLinkNudgeBtn',

  props: {
    paymentLink: {
      type: Object,
      required: true
    }
  },

  components: {
    SnackBar
  },

  data () {
    return {
      loading: false,
      error: {}
    }
  },

  methods: {
    sendNudge () {
      this.loading = true
      this.$api.persist('post', {
        path: `payment-links/${this.paymentLink.id}/nudge`
      })
        .then(() => this.$refs.successBar.open())
        .catch(error => {
          this.error = error
          this.$refs.errorBar.open()
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
