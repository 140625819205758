<template>
  <div>
    <h3 class="mb-3">Service Record Detail</h3>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" style="min-width: 100px;">Ref</th>
            <th class="text-left">Triage</th>
            <th class="text-left">Clean</th>
            <th class="text-left">Agreed</th>
            <th class="text-left">Comp</th>
            <th class="text-left">QC</th>
          </tr>
        </thead>
        <tbody>
          <BookingServiceRecordRow
            v-for="bike in orderedBikes"
            :key="bike.id"
            :job="bike"
          />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import HasBooking from '@/mixins/HasBooking'
import BookingServiceRecordRow from '@/components/bookings/BookingServiceRecordRow'
import orderBy from 'lodash.orderby'

export default {
  name: 'BookingServiceRecordSummary',

  mixins: [ HasBooking ],

  components: {
    BookingServiceRecordRow
  },

  data () {
    return {

    }
  },

  computed: {
    orderedBikes () {
      return orderBy(this.booking.bikes, 'ref', 'desc')
    }
  }
}
</script>
