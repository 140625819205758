<template>
  <div>
    <div>
      <strong>{{ timelineItem.subject }}</strong>
    </div>

    <v-dialog
      v-model="dialog"
      scrollable
      width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="mt-2"
          color="primary"
          outlined
          x-small
        >
          View SMS
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          <strong class="mr-2">Subject:</strong> {{ timelineItem.subject }}
        </v-card-title>

        <v-card-text class="pt-4">
          <iframe :src="timelineItem.content_view_url" width="100%" frameborder="0" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HasTimelineItem from '@/mixins/HasTimelineItem'

export default {
  name: 'BookingTimelineItemSMS',

  mixins: [ HasTimelineItem ],

  data () {
    return {
      dialog: false
    }
  }
}
</script>

<style lang="css" scoped>
</style>