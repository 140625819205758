<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Duplicate Job</span>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col
                class="py-0"
                cols="12"
                sm="6"
              >
                <v-dialog
                  ref="collectionDate"
                  v-model="collectionDateModal"
                  :return-value.sync="form.collection_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="$moment(form.collection_date).format('ddd Do MMM')"
                      class="mt-4"
                      label="Collection Date"
                      outlined
                      dense
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="form.collection_date"
                    :min="$moment().format('YYYY-MM-DD')"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="collectionDateModal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.collectionDate.save(form.collection_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                sm="6"
              >
                <v-dialog
                  ref="returnDate"
                  v-model="returnDateModal"
                  :return-value.sync="form.return_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="$moment(form.return_date).format('ddd Do MMM')"
                      class="mt-4"
                      label="Return Date"
                      outlined
                      dense
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="form.return_date"
                    :min="$moment().add(1, 'day').format('YYYY-MM-DD')"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="returnDateModal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.returnDate.save(form.return_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row class="py-0">
              <v-col
                class="py-0"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="form.complaint_category"
                  :rules="[v => !!v || 'Reason is required']"
                  :items="availableComplaintReasons"
                  label="Complaint Category"
                  item-text="label"
                  item-value="value"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                sm="6"
              >
                <v-textarea
                  v-model="form.complaint_reason"
                  rows="2"
                  label="Reason"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="duplicateJob"
          >
            Duplicate Job <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar error ref="errorBar">
      There was an error duplicating this job!
    </SnackBar>
    <SnackBar success ref="successBar">
      Job duplicated successfully!
    </SnackBar>
  </div>
</template>
<script>
import HasDialog from '@/mixins/HasDialog'
import HasBike from '@/mixins/HasBike'
import HasBooking from '@/mixins/HasBooking'
import HasForm from '@/mixins/HasForm'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'BikeDuplicateDialog',

  mixins: [ HasDialog, HasBike, HasBooking, HasForm ],

  components: {
    FormErrors,
    SnackBar
  },

  data () {
    return {
      collectionDateModal: false,
      returnDateModal: false,
      complaintReasons: {},
      form: {},
      loading: false
    }
  },

  mounted () {
    this.getComplaintReasons()
    this.form.collection_date = this.$moment().add(1, 'day').format('YYYY-MM-DD')
    this.form.return_date = this.$moment().add(3, 'day').format('YYYY-MM-DD')
  },

  computed: {
    availableComplaintReasons () {
      var reasons = []
      Object.keys(this.complaintReasons).forEach(key => {
        reasons.push({
          label: this.complaintReasons[key],
          value: key
        })
      })
      return reasons
    }
  },

  methods: {
    getComplaintReasons () {
      this.$api.get({
        path: 'bike-complaint-reasons',
      })
        .then(data => {
          this.complaintReasons = data
        })
    },

    duplicateJob () {
      this.loading = true
      this.$refs.formErrors.clear()
      this.$api.persist('post', {
        path: `bookings/${this.booking.id}/bikes/${this.bike.id}/copy`,
        object: {
          ...this.form,
          ...{ type: 'JOB_TYPE_C&R_COMPLAINT' }
        }
      }) 
        .then(() => {
          this.$refs.successBar.open()
          this.closeDialog()
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
